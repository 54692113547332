import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { HamburgerButton } from "react-hamburger-button";

import { LockIcon } from "../icons/lock";

const SearchIcon = ({ onSearchClick }) => (
  <button className="search-icon" onClick={onSearchClick}>
    <svg width="18" height="18">
      <g
        fill="none"
        stroke="#444444"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <circle cx="6.5" cy="6.5" r="6.5" />
        <path d="M11.5 11.5l5.756 5.756" strokeLinecap="square" />
      </g>
    </svg>
  </button>
);
export const Header = ({ user, onSearchClick, extraClass }) => {
  const [topMenuOpen, setTopMenuOpen] = useState(false);
  const toggleTopMenuOpen = () => {
    setTopMenuOpen(!topMenuOpen);
  };
  return (
    <div className={"header " + extraClass}>
      <div className="header-content">
        <div className="logo">
          <Link to="/">Njála</Link>
        </div>
        <div className="header-right">
          <div className="top-menu-burger">
            <HamburgerButton
              open={topMenuOpen}
              onClick={toggleTopMenuOpen}
              width={30}
              height={17}
              strokeWidth={2}
              color="#582841"
              animationDuration={0.5}
            />
          </div>
          <ul
            className={
              "top-menu " + (topMenuOpen ? "top-menu-open" : "top-menu-closed")
            }
          >
            <li className="top-menu-item">
              <NavLink to="/umsoguna">Um söguna</NavLink>
            </li>
            {user && user.hasAccess && (
              <>
                <li className="top-menu-item">
                  <NavLink to="/kort">Staðakort</NavLink>
                </li>
                <li className="top-menu-item">
                  <NavLink to="/verkefni/glaerur">Glærur</NavLink>
                </li>
                <li className="top-menu-item">
                  <NavLink to="/verkefni/hop">Verkefni</NavLink>
                </li>
                <li className="top-menu-item">
                  <NavLink to="/gullkistan">Gullkistan</NavLink>
                </li>
              </>
            )}
            <li className="top-menu-item login-button">
              {user && (
                <a href="/logout">
                  <LockIcon /> Útskráning
                </a>
              )}
              {!user && (
                <a href="/login">
                  <LockIcon /> Innskráning
                </a>
              )}
            </li>
            {onSearchClick && (
              <li className="top-menu-item top-menu-search">
                <SearchIcon onSearchClick={onSearchClick} />
              </li>
            )}
          </ul>
        </div>
        {/* {!user && (
          <div className="profile-links">
            <a href="/login">Innskráning</a>
          </div>
        )}
        {user && (
          <div className="profile-links">
            <a href="/logout">Útskráning</a>
            <img
              src={user.picture}
              className="profile-image"
              alt={user.displayName}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
