import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

export const AboutTheScript = props => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="">
      <div className="about-section">
        <h2>Um handrit Brennu-Njáls sögu</h2>
        <div className="about-section-text">
          <p>
            Eins og aðrar Íslendingasögur er Brennu-Njáls saga aðeins varðveitt
            í eftirritum og enginn veit hver er höfundur sagnanna. Ekki er vitað
            nákvæmlega hvenær sagan var fyrst samin en talið er það hafi verið í
            kringum 1280 (Einar Ól. Sveinsson, 1971: LXXXIV). Fimm elstu
            handritin eru talin vera frá því um 1300 og er í því sambandi stuðst
            við stafagerð og stafsetningu (Einar Ól. Sveinsson, 1971: LXXV).{" "}
          </p>
          <p>
            Milli 50–60 handrit eru til af Njáls sögu á opinberum bókasöfnum,
            nítján þeirra eru talin vera frá 1300–1550. Allt eru það
            skinnhandrit og er ekkert þeirra heilt. Alls eru til um 60 handrit
            af sögunni, fimm elstu handritin eru frá því um 1300, það yngsta frá
            seinni hluta 19. aldar (Við upptök Njálu, 2014). Fjöldi eftirrita
            ber þess vitni hve vinsæl sagan hefur verið hjá landsmönnum fyrr á
            öldum og hefur hún löngum verið talin merkasta Íslendingasagan.
          </p>
          <p>
            Elstu handrit Njálu eru Gráskinna (GKS 2870 4to) og Reykjabók (AM
            468 4to) og eru taldar frá því um 1300. Kálfalækjarbók (AM 133 fol.)
            er talin frá fyrri hluta 14. aldar og Möðruvallabók frá 1330–1370.
            „Ekkert þessara handrita er alveg heilt og til viðbótar þeim eru
            varðveitt átta styttri brot frá tímabilinu 1300–1375“ (Við upptök
            Njálu, 2014). Þær útgáfur sem seinna hafa verið gefnar út af sögunni
            á prentuðu formi eru því saman settar úr mismunandi handritum. Til
            að mynda studdist Konráð Gíslason í sinni útgáfu (frá seinni hluta
            19. aldar) að megninu til á Reykjabók en Einar Ól. Sveinsson byggði
            sína útgáfu (frá 1954) að mestu leyti á Möðruvallabók (Vésteinn
            Ólason, 2011).
          </p>
        </div>
      </div>
      <div className="about-story-parts">
        <div className="about-section">
          <h2>Heimildir</h2>
          <div className="about-section-text sources">
            <p>
              Einar Ól. Sveinsson (gaf út). (1971). Íslenzk fornrit XII. bindi.
              Brennu-Njáls saga. Reykjavík: Hið íslenzka fornritafélag
            </p>
            <p>
              Við upptök Njálu. (2014, 23.12). mbl.is. Sótt af
              https://www.mbl.is/greinasafn/grein/1536111/
            </p>
            <p>
              Vésteinn Ólason. (2011, 19.8). Hvað getið þið sagt mér um Einar
              Ólaf Sveinsson og framlag hans til íslenskra fræða? Sótt af
              https://www.visindavefur.is/svar.php?id=60329
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
