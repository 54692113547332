import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Download } from "../icons/download";

const glaerur = [
  {
    title: "Kaflar 1-17.pptx",
    description: "Það helsta úr köflum 1-17",
    link: "/nidurhal/verkefni/Kaflar_1-17.pptx",
    image: "",
  },
  {
    title: "Kaflar 18-31.pdf",
    description: "Það helsta úr köflum 18-31",
    link: "/nidurhal/verkefni/Kaflar_18-31.pdf",
    image: "",
  },
  {
    title: "Kaflar 32-45.pdf",
    description: "Það helsta úr köflum 32-45",
    link: "/nidurhal/verkefni/Kaflar_32-45.pdf",
    image: "",
  },
  {
    title: "Kaflar 46-56.pdf",
    description: "Það helsta úr köflum 46-56",
    link: "/nidurhal/verkefni/Kaflar_46-56.pdf",
    image: "",
  },
  {
    title: "Kaflar 57-66.pdf",
    description: "Það helsta úr köflum 57-66",
    link: "/nidurhal/verkefni/Kaflar_57-66.pdf",
    image: "",
  },
  {
    title: "Kaflar 67-77.pdf",
    description: "Það helsta úr köflum 67-77",
    link: "/nidurhal/verkefni/Kaflar_67-77.pdf",
    image: "",
  },
  {
    title: "Kaflar 78-81.pdf",
    description: "Það helsta úr köflum 78-81",
    link: "/nidurhal/verkefni/Kaflar_78-81.pdf",
    image: "",
  },
  {
    title: "Kaflar 82-97.pdf",
    description: "Það helsta úr köflum 82-97",
    link: "/nidurhal/verkefni/Kaflar_82-97.pdf",
    image: "",
  },
  {
    title: "Lýtings þáttur 98-99.pdf",
    description: "Það helsta úr köflum 98-99",
    link: "/nidurhal/verkefni/Lytings_thattur_k.98-99.pdf",
    image: "",
  },
  {
    title: "Kaflar 100-106.pdf",
    description: "Það helsta úr köflum 100-106",
    link: "/nidurhal/verkefni/Kaflar_100-106.pdf",
    image: "",
  },
  {
    title: "Kaflar 107-112.pdf",
    description: "Það helsta úr köflum 107-112",
    link: "/nidurhal/verkefni/Kaflar_107-112.pdf",
    image: "",
  },
  {
    title: "Kaflar 113-132.pdf",
    description: "Það helsta úr köflum 112-132",
    link: "/nidurhal/verkefni/Kaflar_113-132.pdf",
    image: "",
  },
];
export const Glaerur = (props) => (
  <>
    <Header user={props.user} />
    <div className="project-image" />
    <div className="one-col sub-page">
      <div className="projects">
        <div className="project-list">
          {glaerur.map(({ link, title, description }) => (
            <a className="project" href={link} key={link}>
              <div className="project-text">
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
              <Download />
            </a>
          ))}
        </div>
      </div>
    </div>
    <Footer />
  </>
);
