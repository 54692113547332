import React from "react";
import { NavLink } from "react-router-dom";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Download } from "../icons/download";

const hopVerkefni = [
  {
    title: "Jafnrétti og Njála",
    description:
      "Þetta verkefni gengur út á að skoða og meta samskipti nokkurra persóna í Njálu út frá jafnréttishugtakinu. Þá er tekið fyrir hvað það er sem skapar mismun og/eða forréttindi í lífi þeirra, með hvaða hætti það kemur fram og hvað það hefur í för með sér.",
    link: "/nidurhal/verkefni/jafnretti-og-njala.docx",
    image: "",
  },
  {
    title: "Lýðræði og mannréttindi og Njála",
    description:
      "Þetta verkefni gengur út á að skoða og meta að hvaða leyti lýðræði og mannréttindi birtast í Njálu sem og að hvaða leyti því er ábótavant. Tekin eru stutt dæmi úr Njálu, þau rædd og metin. Einnig eru tekin fyrir tvö stutt dæmi úr Laxdælu annars vegar og hins vegar úr Hrafnkelssögu.",
    link: "/nidurhal/verkefni/LydrædiOgMannréttindiOgNjala.docx",
    image: "",
  },
  {
    title: "Tveggja turna keppni",
    description:
      "Spurningakeppni um fall Gunnars. Unnið með þá atburði og persónur sem við sögu koma og leiða til dauða Gunnars. Sambærileg verkefni má vinna úr öðrum hlutum sögunnar.",
    link: "/nidurhal/verkefni/TveggjaTurnaKeppni.docx",
    image: "",
  },
  {
    title: "Blaðamannafundur",
    description:
      "Blaðamannafundur um orsök vígs Höskuldar Hvítanessgoða og brennuna að Bergþórshvoli. Sambærileg verkefni má vinna úr öðrum hlutum sögunnar.",
    link: "/nidurhal/verkefni/bladamannafundurVigHoskuldarOgBrennanAdBergþorshvoli.docx",
    image: "",
  },
];

const skapandiVerkefni = [
  {
    title: "Stuttmynd úr Brennu-Njáls sögu",
    description:
      "Leiðbeiningar um hvernig er best að standa að verki í vinnu við stuttmynd þar sem unnið er með valda atburðarrás úr Njálu. Einnig hvernig verkefnið er metið út frá lykilhæfni og hæfniviðmiðum ásamt matskvarða.",
    link: "/nidurhal/verkefni/StuttmyndUrBrennuNjalsSogu.docx",
    image: "",
  },
  {
    title: "Teiknimyndasaga úr Brennu-Njáls sögu",
    description:
      "Leiðbeiningar með teiknimyndasögu þar sem unnið er með valda atburðarrás úr Njálu. Hægt að velja milli þriggja teiknimyndaforrita. Leiðbeiningarmyndbönd um hvernig vinna má með forritin eru að finna á Youtube. Námsmat og matskvarði.",
    link: "/nidurhal/verkefni/TeiknimyndasagaUrBrennuNjalsSogu.docx",
    image: "",
  },
  {
    title: "Tímarit úr Brennu-Njáls sögu",
    description:
      "Leiðbeiningar um hvernig vinna á fjölbreytt og áhugavert tímarit úr Njálu. Verkefnið gengur einkum út á sköpun. Umbrotsforrit fyrir tímarit er í Publisher í PC og í Word í Mac. Námsmat og matskvarði.",
    link: "/nidurhal/verkefni/TimaritIUrBrennuNjalsSogu.docx",
    image: "",
  },
  {
    title: "Ferilskrá og meðmælabréf. Verkefni úr köflum 19-33",
    description:
      "Gunnar á Hlíðarenda vill ganga í væringjaher Miklagarðskeisara.",
    link: "/nidurhal/verkefni/Kaflar19-32-Gunnar-a-Hlidarenda",
    image: "",
  },
  {
    title: "Innlifunarverkefni - k.1-17",
    description:
      "Innlifunarverkefni eiga að endurspegla atburði í sögunni og vera á þann hátt fræðandi um þá persónu sem valið er að vinna með og þeim atburðum sem hún/hann tengist.",
    link: "/nidurhal/verkefni/InnlifunarverkefniK.1-17.docx",
    image: "",
  },
  {
    title: "Matarboðið að Bergþórugötu",
    description:
      "Gunnar og Hallgerður búa við Gunnarsgötu í Norðurmýrinni, Njáll og Bergþóra búa við Bergþórugötu, skammt frá. Setjið matarboðið í nútímabúning, sjá nánar á skjali.",
    link: "/nidurhal/verkefni/Matarbodid_ad_Bergthorugotu.docx",
    image: "",
  },
  {
    title: "Skapandi verkefni úr köflum 46-56",
    description:
      "Þú ert förumaður/förukona og segir frá því hvaða augum þú lítur það sem gerist í þessum köflum.",
    link: "/nidurhal/verkefni/SkapandiVerkefniK46-56.docx",
    image: "",
  },
  {
    title: "Plakat um Gunnars þátt - hópaverkefni",
    description: "Unnið með efni úr Gunnars þætti, þ.m.t. húskarlavígin.",
    link: "/nidurhal/verkefni/Plakat_um_Gunnars_thatt.pptx",
    image: "",
  },
  {
    title: "Kolskeggur eða Hallgerður",
    description:
      "Þú ert annað hvort Kolskeggur eða Hallgerður og segir frá atburðum eins og þú sért önnur hvor þessara persóna.",
    link: "/nidurhal/verkefni/Kolskeggur_eda_Hallgerdur.docx",
    image: "",
  },
  {
    title: "Ýmis skapandi verkefni úr Njálu.docx",
    description:
      "Fjölbreytt skapandi verkefni sem væri hægt að hafa sem lokaverkefni úr sögunni.",
    link: "/nidurhal/verkefni/Ymis_skapandi_verkefni_ur_Njalu.docx",
    image: "",
  },
  {
    title: "Kynningarverkefni úr k. 93-111",
    description:
      "Persónur úr sögunni tjá sig um það sem er að gerast í þessum hluta sögunnar.",
    link: "/nidurhal/verkefni/KynningarverkefniUrK.93-111.docx",
    image: "",
  },
];

const onnurVerkefni = [
  {
    title: "Ígrundanir",
    description:
      "Í þessum verkefnum mynda nemendur sér rökstuddar skoðanir á því efni sem þeir velja að vinna með hverju sinni. Það varðar atburðarrás og þær persónur sem henni tengjast. Mikilvægt er að hafa endursagnir í lágmarki en eigin skoðanir í forgrunni.",
    link: "/nidurhal/verkefni/igrundanir.docx",
    image: "",
  },
  {
    title: "Verkefni úr köflum 1-8",
    description: "Tvö verkefni, annað úr köflum 1-4 og hitt úr köflum 1-8",
    link: "/nidurhal/verkefni/VerkefniUrKoflum1-8.docx",
    image: "",
  },
  {
    title: "Verkefni úr köflum 1-17",
    description: "Yfirlitsverkefni úr fyrsta hluta sögunnar",
    link: "/nidurhal/verkefni/VerkefniUrKoflum1-17.docx",
    image: "",
  },
  {
    title: "Verkefni úr köflum 1-12",
    description:
      "Paraverkefni sem snýst um að átta sig að helstu persónum, tengslum milli þeirra og tengslum við atburðarrásina. Umræður í lokin.",
    link: "/nidurhal/verkefni/VerkefniUrkoflum1-12.docx",
    image: "",
  },
  {
    title: "Verkefni úr köflum 8-17",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/VerkefniUrKoflum8-17.docx",
    image: "",
  },
  {
    title: "Verkefnavinna úr köflum 19-31",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/VerkefniUrKoflum19-31.docx",
    image: "",
  },
  {
    title: "Ferilskrá og meðmælabréf. Verkefni úr köflum 19-33",
    description:
      "Gunnar á Hlíðarenda vill ganga í væringjaher Miklagarðskeisara.",
    link: "/nidurhal/verkefni/Kaflar19-32-Gunnar-a-Hlidarenda",
    image: "",
  },
  {
    title: "Spurningar úr köflum 32-45",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/VerkefniUrKoflum32-45.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 46-56",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum46-56.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 46-81",
    description: "",
    link: "/nidurhal/verkefni/SpurningarUrKoflum46-81.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 82-90",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum82-90.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 93-111",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/VerkefniUrKoflum93-111.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 112-130",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum112-130.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 125-134",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum125-134.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 135-145",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum135-145.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 146-151",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum146-151.docx",
    image: "",
  },
  {
    title: "Spurningar úr köflum 152-159",
    description: "Lesskilningsverkefni",
    link: "/nidurhal/verkefni/SpurningarUrKoflum152-159.docx",
    image: "",
  },
];
const Projects = (props) => (
  <>
    <Header user={props.user} />
    <div className="project-image" />
    <div className="one-col sub-page">
      <div className="sub-page-intro">
        <h2 className="sub-page-title">Verkefni</h2>
        <p className="sub-page-description">
          Í verkefnamöppunni eru að finna fjölbreytt verkefni sem tengjast
          sögunni.
        </p>
        <div className="sub-nav-container">
          <div className="sub-nav">
            <NavLink to="/verkefni/hop" className="sub-nav-link">
              Hópverkefni
            </NavLink>
            <NavLink to="/verkefni/skapandi" className="sub-nav-link">
              Skapandi verkefni
            </NavLink>
            <NavLink to="/verkefni/onnur" className="sub-nav-link">
              Önnur verkefni
            </NavLink>
          </div>
        </div>
      </div>
      <div className="projects">
        <div className="project-list">
          {props.projects.map((project) => (
            <a className="project" href={project.link} key={project.link}>
              <div className="project-text">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
              <Download />
            </a>
          ))}
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export const HopVerkefni = (props) => (
  <Projects projects={hopVerkefni} {...props} />
);

export const SkapandiVerkefni = (props) => (
  <Projects projects={skapandiVerkefni} {...props} />
);

export const OnnurVerkefni = (props) => (
  <Projects projects={onnurVerkefni} {...props} />
);
