import React from "react";

export const LockIcon = () => (
  <svg width={16} height={16} fill="none">
    <path
      d="M13.5 15.5h-11a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zm-9-9V4a3.5 3.5 0 1 1 7 0v2.5"
      stroke="#828282"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
      stroke="#828282"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
