import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

export const VoldAettartengslVirding = props => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="">
      <div className="about-section">
        <h2>Völd, ættartengsl og virðing</h2>
        <div className="about-section-text">
          <p>
            Meginatburðir Brennu-Njáls sögu gerast á Suðurland þar sem
            misjafnlega vel ættaðir og innrættir höfðingjar takast á. Sagan
            segir einnig frá atburðum sem gerast vestur í Dölum og þá teygir
            sagan sig allt til Vopnafjarðar þegar Flosi Þórðarson fer í liðsbón
            til höfðingja á Austurlandi eftir brennuna. Sagan segir einnig af
            ferðum Íslendinga erlendis, meðal annars til Noregs, Orkneyja,
            Írlands og jafnvel allt suður til Rómar.
          </p>
          <p>
            Þau átök sem fara af stað í sögunni með húskarlavígunum og síðan
            þeim frásagnarverðu atburðum sem eiga sér stað í sögunni byggja ekki
            síst á þeirri togstreitu sem er þegar til staðar í samfélaginu áður
            en átökin fara af stað. Togstreitan byggir á misgagnsærri
            stéttaskiptingu þar sem þær persónur sem gátu rakið ættir sínar til
            stórmenna í Noregi töldu sig standa hvað hæst í samfélagsstiganum.
            Togstreitan byggir um leið á ættartengslum og baráttu um völd á
            Suðurlandi. Þar standa ættir saman, bæði í því að verja völd sín og
            að auka þau. Goðorð höfðu mikið vægi þegar völd voru annars vegar.
            Völd byggja þó ekki eingöngu á ættartengslum og goðorðum heldur
            einnig heiðri. Þegar Skarphéðinn segir Flosa Þórðarson vera „brúður
            Svínafellsáss“ og að hann geri Flosa að konu „hverja hinu níundu
            nótt“ veit Flosi að völd og virðing hans er í hættu og þá um leið
            staða hans í samfélaginu (129. kafli). Þetta á sér stað á Þingvöllum
            eftir víg Höskuldar Hvítanesgoða. Flosi veit að orð Skarphéðins munu
            verða hermd í nánast öllum baðstofum landsins þar sem hann verður
            athlægi allra. Hann á því ekki annarra kosta völ en að hafna sáttum
            en leita hefnda til að endurheimta karlmennsku sína sem svo
            óvirðulega var vegið að með orðum Skarphéðins. Og þá um að halda
            virðingu sinni og völdum. Hefndina fær Flosi en hún er dýru verði
            keypt.
          </p>
          <p>
            Mörg dæmi eru um að voldugir menn fari offari án þess að það komi
            niður á virðingu þeirra. Þannig er Guðmundur ríki sagður sitja „yfir
            virðingu allra höfðingja fyrir norðan Öxnadalsheiði svo að sumir
            létu bústaði sína en suma tók hann af lífi en sumir létu goðorð sín
            fyrir honum“. Strax á eftir þessari lýsingu segir að frá honum sé
            komið „allt hið mesta mannval á landinu: Oddaverjar og Sturlungar og
            Hvammverjar og Fljótamenn og Ketill biskup og margir hinir bestu
            menn“ (113. kafli). Það kemur greinilega fram í Njálu að Guðmundur
            nýtur mikillar virðingar þrátt fyrir það hvernig hann kemur fram við
            sveitunga sína norðan heiða. Því má segja að völd séu virðing svo
            framarlega sem menn haldi karlmennskuorðspori sínu.
          </p>
          <p>
            <strong>Eru Gunnar og Njáll höfðingjar?</strong>
          </p>
          <p>
            Þó Gunnar á Hlíðarenda sé mikil hetja og Njáll sé mesti lögspekingur
            landsins hafa þeir hvorugir goðorð.
          </p>
          <p className="quote">
            Samfélagið sem lýst er í sögunni er ættarsamfélag þar sem völd eru
            einkum bundin goðorðum og þar standa persónurnar misvel til metorða.
            Hvorki Njáll né Gunnar fara með goðorð þó höfundi takist svo vel að
            sýna höfðingskap þeirra að lesandinn geri ósjálfrátt ráð fyrir að
            þeir hafi einhver slík völd. (Brennu-Njáls saga, 2008: x)
          </p>
          <p>
            Gunnari er í sögunni ekki bara lýst sem mikilli hetju heldur er hann
            sagður vinsæll maður. Langafi Gunnars í föðurætt var þó Hrafn
            lögsögumaður en einhverra hluta vegna hefur goðorð þess leggs ekki
            skilað sér áfram.
          </p>
          <p>
            Í föðurætt tengist Gunnar Merði gígju og hans ætt en það kom þó ekki
            til af góðu. Bróðurafi Gunnars, Steinn Baugsson, vóg Sighvat rauða
            (föður Marðar gígju) vegna deilna um hvor þeirra ætti að fara á
            undan í ferjuna við Sandhóla til þess að komast yfir Þjórsá (í
            Landnámuútgáfum er sagt að það hafi verið Sigmundur, sonur
            Sighvats). Deilur sem undu upp á sig í kringum það víg og enduðu með
            því að Rannveig, bróðurdóttur Marðar gígju, var gefin Hámundi sem
            sáttargjöf. Einn sona Rannveigar og Hámundar er sjálfur Gunnar á
            Hlíðarenda. Lítil tengsl voru á milli ættar Rannveigar og Gunnars,
            til að mynda er dóttursonur Marðar gígju, Mörður Valgarðsson, illa
            til Gunnars og á sinn þátt í að koma honum í gröfina. Má vel draga
            þá ályktun að Mörður yngri erfi það að langafi hans hafi verið
            drepinn af Steini Baugssyni, bróðurafa Gunnars.
          </p>
          <p>
            Draga má í efa að Gunnar teljist meðal höfðingja. Orðspor hans
            byggir einkum að atgervi hans. Það eru hins vegar höfðingjar allt í
            kringum hann. Frændi hans, Þráinn Sigfússon, er sagður
            „virðingarmaður mikill“ (34. kafli) og eftir heimkomuna frá Noregi
            er hann sagður hafa „sextán karla víga á bæ sínum og riðu átta með
            honum hvert er hann fór (91. kafli). Gunnar siglir utan með
            Hallvarði hvíta, víkverskum manni, og fer aðeins Kolskeggur bróður
            hans með honum. Einnig er Gunnar jafnan fámennur í ferðum sínum
            innanlands, hann er ýmist einn á ferð eða með bræðrum sínum. Þá er
            Gunnar einn karla heima þegar Gissur hvíti fer með liðsafnað að
            honum til þess að drepa hann. Hvar eru vinirnir? Þess er víða getið
            í Íslendingasögunum að höfðingjar haldi mikið lið vígfærra manna á
            búum sínum ef þeir telja sig í hættu einhverra hluta vegna. En hvort
            sem það er vegna vinaleysis Gunnars eða oflætis, að hann telji sig
            ekki þurfa neitt að óttast, þá er staðreyndin sú að hann hann hefur
            ekki höfðingja hátt á að halda fjölda vígfærra manna á bæ sínum til
            verndar sér.
          </p>
          <p>
            Vestur af Fljótshlíðinni var veldi Marðar gígju og þar bjó afkomandi
            hans, goðinn Mörður Valgarðsson. Faðir Marðar, Valgarður grái, getur
            talið sér það til tekna að geta rakið ættir sínar til allt aftur til
            Haraldar konungs hilditannar. Þar bjó einnig annar goði, Úlfur
            aurgoði, föðurbróðir Marðar „er Oddaverjar eru komnir frá“ (25.
            kafli).
          </p>
          <p>
            Otkell á Kirkubæ er bæði vel efnaður og af höfðingjum kominn þó ekki
            fari mikið fyrir atgervi hans né mannkostum. Afabróðir hans,
            Ketilbjörn gamli, nam allt Grímsnes, Laugarvatn og hluta af
            Biskupstungunum. Ketilbjörn bjó að Mosfelli og síðar sonarsonur
            hans, Gissur hvíti. Að Hlíð í Biskupstungum bjó Geir goði en þeir
            Gissur eru systkinabörn. Báðir eru þeir mikilsmetnir höfðingjar.
            Otkell er því frændi Gissurar hvíta og Geirs goða og þar liggur
            skýringin í því að Skammkell, vinur Otkels, vill að hann leiti ráða
            hjá þeim þegar Gunnar býður Otkatli bætur eftir þjófnaðinn sem
            Hallgerður langbrók stóð fyrir að bæ Otkels.
          </p>
          <p>
            Suður með sjó, í Landeyjunum, býr Njáll Þorgeirsson og fjölskylda
            hans. Athyglisvert er að Landeyingar höfðu engan málsvara í formi
            goða. Því má draga þá ályktun að á þeim tíma sem Njáls saga gerist
            hafi Landeyingar verið einhvers konar undirmálsmenn í íslensku
            samfélagi þrátt fyrir Njáll hafi haft sterka stöðu vegna visku
            sinnar og lögspeki. Njáll nýtti sér um síðir sambönd sín og færði
            Landeyingum goða, Höskuld Þráinsson. Þar með var valdajafnvæginu
            raskað en aðeins um tíma því goðinn Mörður Valgarðsson sá um að koma
            Höskuldi fyrir kattarnef. Fylgdi hann þar ráðagerð föður síns og
            vélaði Njálssyni til að drepa Höskuld. Með í þeirri aðgerð var mágur
            Njálssona, Kári Sölmundarson og Mörður sjálfur.
          </p>
          <p>
            Valdaöflin, með Flosa Þórðarson í broddi fylkingar, ákváðu svo að
            losa sig við þessa óeirðamenn úr Landeyjum og þar með féll eini
            málsvari Landeyjarbyggðar, Njáll Þorgeirsson. Engir aðrir en
            heimamenn voru að Bergþórshvoli þegar Flosi kemur þar að þrátt fyrir
            að frést hafi að Flosi væri með hundrað manna lið með sér á leið í
            Landeyjarnar. Höfðingjar hefðu búist til varna. Fyrr í sögunni
            losuðu Gissur hvíti og Mörður Valgarðsson sig við Gunnar á
            Hlíðarenda. Í sögulok eru valdajafnvægin í samfélaginu á Suðurlandi
            með sama hætti og í upphafi sögunnar. Ef eitthvað er þá hefur staða
            Marðar Valgarðssonar styrkst.
          </p>
        </div>
      </div>
      <div className="about-story-parts">
        <div className="about-section">
          <h2>Heimildir</h2>
          <div className="about-section-text sources">
            <p>
              Sighvatur rauði og víg við Sandhólaferju. (2004, 8.5). mbl.is.
              Sótt af https://www.mbl.is/greinasafn/grein/796699/
            </p>
            <p>
              Við upptök Njálu. (2014, 23.12). mbl.is. Sótt af
              https://www.mbl.is/greinasafn/grein/1536111/
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
