import { createChapterModel } from "./story";

//TODO better error handling know all server error cause redirect to login

const createApi = () => {
  let chaptersPromise;
  let model;
  const getChapters = () => {
    if (!chaptersPromise) {
      chaptersPromise = fetch(`/njala/kaflar.json`).then((res) => {
        if (res.ok) {
          return res.json().then((res) => {
            model = createChapterModel(res);
            return res;
          });
        } else {
          window.location.replace("/login");
        }
      });
    }
    return chaptersPromise;
  };
  const api = {
    getChapter: (chapter) => getChapters().then((res) => res[chapter]),
    getChapterTitles: () => {
      return getChapters().then(() => model.chapterTitles);
    },
    search: (text) => {
      if (model) {
        return model.searchChapters(text);
      } else {
        return [];
      }
    },
  };
  return api;
};

const createQuizzesApi = () => {
  const qApi = {
    getQuizzes: () =>
      fetch("/api/v1/krossaspurningar").then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          window.location.replace("/login");
        } else {
          return res.text().then((errData) => {
            throw new Error(errData);
          });
        }
      }),
    getQuizzesById: (id) =>
      fetch("/api/v1/krossaspurningar/" + id).then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          window.location.replace("/login");
        } else {
          return res.text().then((errData) => {
            throw new Error(errData);
          });
        }
      }),
    selectOption: (quizzesId, questionId, valueId) => {
      console.log(quizzesId, questionId, valueId);
      return fetch("/api/v1/krossaspurningar/svar", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizzesId,
          questionId,
          valueId,
        }),
      }).then((res) => res.ok);
    },
    confirmQuizzes: (quizzesId) => {
      return fetch("/api/v1/krossaspurningar/ljuka", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizzesId,
        }),
      }).then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          window.location.replace("/login");
        } else {
          return res.text().then((errData) => {
            throw new Error(errData);
          });
        }
      });
    },
  };
  return qApi;
};

const createPaymentApi = () => {
  const paymentApi = {
    getPaymentData: () =>
      fetch("/api/v1/pay/data", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.replace("/login");
        }
      }),
    getAudiobookPaymentData: () =>
      fetch("/api/v1/pay/audio_data", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.replace("/login");
        }
      }),
  };
  return paymentApi;
};

export const api = {
  ...createApi(),
  ...createQuizzesApi(),
  ...createPaymentApi(),
};
