export const places = [
  {
    lat: 63.726273,
    lng: -19.914572,
    name: "Hlíðarendi",
    people: ["Gunnar Hámundarson", "Hallgerður langbrók"],
    rank: 1,
  },
  {
    lat: 63.736219,
    lng: -19.972393,
    name: "Grjótá",
    people: ["Þráinn Sigfússon", "Þorgerður Glúmsdóttir"],
    rank: 10,
  },
  {
    lat: 63.661903,
    lng: -19.896309,
    name: "Mörk",
    people: ["Ketill Sigfússon", "Þorgerður Njálsdóttir"],
    rank: 10,
  },
  {
    lat: 63.643507,
    lng: -19.945893,
    name: "Dalur",
    people: [
      "Runólfur Úlfsson (aurgoða)",
      "Faðir Runólfs er bróðir Valgarðar gráa",
    ],
    rank: 10,
  },
  {
    lat: 63.642544,
    lng: -20.332476,
    name: "Bergþórshvoll",
    people: ["Njáll Þorgeirsson", "Bergþóra Skarphéðinsdóttir"],
    rank: 1,
  },
  {
    lat: 63.81372,
    lng: -20.08348,
    name: "Keldur",
    people: [
      "Þar býr Ingjaldur, bróðir Hróðnýjar en",
      "með henni á Njáll launsoninn Höskuld",
    ],
    rank: 12,
  },
  {
    lat: 63.928156,
    lng: -20.198009,
    name: "Þingskálar",
    people: [
      "Héraðsþing Rangæinga þar sem mál Gunnars",
      "á Hlíðarenda voru meðal annars tekin fyrir",
    ],
    rank: 8,
  },
  {
    lat: 64.267297,
    lng: -21.088716,
    name: "Þingvellir",
    people: ["Á Þingvöllum voru  hin stærri mál tekin fyrir"],
    rank: 1,
  },
  {
    lat: 63.834129,
    lng: -20.694314,
    name: "Sandhólaferja",
    people: [
      "Við ferjuna vóg Steinn Baugsson, afabróðir Gunnars",
      "á Hlíðarenda föður Marðar gígju, Sighvat",
      "rauða/Sigmund (fer eftir heimildum)",
    ],
    rank: 12,
  },
  {
    lat: 63.774962,
    lng: -20.357061,
    name: "Móeiðarhvoll",
    people: [
      "Deilt er um þetta land í viðskiptum Þorgeirs",
      "Starkaðarsonar og Gunnars á Hlíðarenda",
    ],
    rank: 12,
  },
  {
    lat: 63.7024,
    lng: -20.227356,
    name: "Berjanes",
    people: [
      "Tyrfingur og kemur fram í málaflækjum Njáls",
      "þegar hann liðsinnir Gunnari vegna hestaatsins",
    ],
    rank: 12,
  },
  {
    lat: 63.659663,
    lng: -20.190014,
    name: "Ossabær",
    people: ["Höskuldur Hvítanesgoði", "Hildigunnur Starkaðardóttir"],
    rank: 10,
  },
  {
    lat: 63.720562,
    lng: -20.474429,
    name: "Hólar",
    people: [
      "Þar voru Helgi og Grímur Njálsynir þegar þeir frétta",
      "að Flosi er að fara með lið mikið að Bergþórshvoli",
    ],
    rank: 12,
  },
  {
    lat: 63.543822,
    lng: -19.768984,
    name: "Arnarbælisós",
    people: ["Þar gátu skip tekið land er þau komu utan"],
    rank: 10,
  },
  {
    lat: 63.746817,
    lng: -20.173201,
    name: "Sámsstaðir",
    people: ["Lýtingur, mágur Þráins Sigfússonar"],
    rank: 12,
  },
  {
    lat: 63.784263,
    lng: -19.9920901,
    name: "Þríhyrningshálsar",
    people: ["Starkaður, en synir hans", "fara í hestaat við Gunnar. "],
    rank: 8,
  },
  {
    lat: 63.833719,
    lng: -19.768416,
    name: "Hrappsstaðir",
    people: [
      "Þar á bú Hrappur Örgumleiðason. Hann er þó jafnan",
      "hjá Þráni að Grjótá eftir heimkomuna frá Noregi",
    ],
    rank: 12,
  },
  {
    lat: 63.883147,
    lng: -19.78761,
    name: "Tröllaskógur",
    people: ["Önundur, sá sem á eftir", "að drepa hund Gunnars"],
    rank: 12,
  },
  {
    lat: 63.873233,
    lng: -19.838177,
    name: "Sandgil",
    people: [
      "Þar býr Egill en synir hans fara í",
      "hestaatið gegn Gunnari með sonum Starkaðar",
    ],
    rank: 10,
  },
  {
    lat: 63.899728,
    lng: -20.082471,
    name: "Knafahólar",
    people: ["Þar sitja hestaatsmenn fyrir Gunnari og bræðrum hans"],
    rank: 10,
  },
  {
    lat: 63.830582,
    lng: -20.25688,
    name: "Kirkjubær",
    people: ["Þar býr Otkell með bræðrum sínum"],
    rank: 8,
  },
  {
    lat: 63.790981,
    lng: -20.124098,
    name: "Völlur",
    people: ["Mörður gígja"],
    rank: 8,
  },
  {
    lat: 63.793357,
    lng: -20.202043,
    name: "Hof",
    people: ["Valgarður grái og síðar Mörður sonur hans"],
    rank: 10,
  },
  {
    lat: 63.80528,
    lng: -20.153655,
    name: "Hof annað",
    people: ["Skammkell, vinur Otkels"],
    rank: 10,
  },
  {
    lat: 63.776609,
    lng: -20.391933,
    name: "Oddi",
    people: [
      "Í sögunni segir að Oddaverjar séu komnir frá",
      "Úlfi aurgoða en hann er bróðir Valgarðs gráa",
    ],
    rank: 10,
  },
  {
    lat: 63.642735,
    lng: -19.989843,
    name: "Markarfljót",
    people: ["Þar berjast Njálssynir og Kári við Þráin og menn hans"],
    rank: 10,
  },
  {
    lat: 63.788433,
    lng: -20.198814,
    name: "Eystri-Rangá",
    people: [
      "Áin kemur oft við sögu. Þar er til dæmis fyrsti",
      "bardagi Gunnars, skammt frá Hofi þar sem Mörður býr",
    ],
    rank: 10,
  },
  {
    lat: 63.690315,
    lng: -19.508617,
    name: "Þórsmörk",
    people: [
      "Björn hvíti og Valgerður kona hans. Björn",
      "veitir Kára Sölmundarsyni liðsinni eftir brennuna",
    ],
    rank: 10,
  },
  {
    lat: 63.710421,
    lng: -19.66275,
    name: "Þórólfsfell",
    people: ["Þar á Njáll annað bú"],
    rank: 12,
  },
  {
    lat: 63.676733,
    lng: -19.943963,
    name: "Stóra-Dímon",
    people: [
      "Þar eru Rauðuskriður. Þar er fyrsti húskarlinn",
      "veginn í deilum Hallgerðar og Bergþóru",
    ],
    rank: 10,
  },
  {
    lat: 63.567294,
    lng: -19.616775,
    name: "Raufarfell",
    people: [
      "Viðkomustaður Sigfússona á leið þeirra til Höfðabrekku.",
      "Á leið þeirra til Höfðabrekkju æja þeir, í Kerlingadal,",
      "og lenda þar í bardaga við Kára og Björn hvíta",
    ],
    rank: 12,
  },
  {
    lat: 63.533034,
    lng: -19.518142,
    name: "Skógar",
    people: ["Þorgeir skorargeir og bræður hans eiga þar annað bú"],
    rank: 12,
  },
  { lat: 63.624008, lng: -19.596015, name: "Eyjafjallajökull" },
  { lat: 63.636383, lng: -19.152886, name: "Mýrdalsjökull" },
  {
    lat: 64.294689,
    lng: -20.479192,
    name: "Hlíð",
    people: ["Geir goði"],
    rank: 10,
  },
  {
    lat: 64.164671,
    lng: -20.48868,
    name: "Tunga",
    people: [
      "Ásgrímur Elliðagrímsson. Helgi Njálsson giftist Þórhöllu",
      "dóttur hans og Njáll tekur Þórhall son hans í fóstur og",
      "kennir honum lög",
    ],
    rank: 12,
  },
  { lat: 64.211756, lng: -20.710794, name: "Laugarvatn" },
  {
    lat: 64.126687,
    lng: -20.610992,
    name: "Mosfell",
    people: ["Gissur hvíti"],
    rank: 10,
  },
  { lat: 64.267554, lng: -21.077379, name: "Þingvellir" },
  {
    lat: 64.153179,
    lng: -21.885755,
    name: "Laugarnes",
    people: ["Þar býr Hallgerður langbrók", "eftir annað hjónaband sitt"],
    rank: 8,
  },
  {
    lat: 64.171447,
    lng: -21.913541,
    name: "Engey",
    people: ["Glúmur, Þórarinn og Ragi áttu saman Engey og Laugarnes"],
    rank: 10,
  },
  {
    lat: 64.581235,
    lng: -21.772374,
    name: "Hvítá",
    people: [
      "Við ósa Hvítár tekur Össur, föðurbróðir Hrúts,",
      "land er hann kemur á kaupskipi frá Noregi",
    ],
    rank: 10,
  },
  { lat: 64.690463, lng: -21.594881, name: "Varmaland" },
  {
    lat: 65.064971,
    lng: -21.776085,
    name: "Hrútsstaðir",
    people: ["Þar býr Hrútur Herjólfsson"],
    rank: 10,
  },
  {
    lat: 65.088819,
    lng: -21.729076,
    name: "Höskuldsstaðir",
    people: ["Höskuldur Dala-Kollsson"],
    rank: 5,
  },
  {
    lat: 65.27893,
    lng: -21.857296,
    name: "Sælingsdalur",
    people: ["Þar býr Snorri goði seinni hluta ævi sinnar"],
    rank: 12,
  },
  {
    lat: 65.31348,
    lng: -22.595111,
    name: "Bjarneyjar",
    people: [
      "Þessar eyjar á Þorvaldur, fyrsti eiginmaður Hallgerðar langbrókur",
    ],
    rank: 12,
  },
  {
    lat: 65.787417,
    lng: -21.554006,
    name: "Svanshóll",
    people: ["Svanur, móðurbróðir Hallgerðar langbrókur"],
    rank: 8,
  },
  {
    lat: 64.445549,
    lng: -20.992958,
    name: "Hallbjarnarvörður",
    people: [
      "Bændur af Vesturlandi ríða fram hjá",
      "Hallbjarnarvörðum á leið sinni til Þingvalla",
    ],
    rank: 10,
  },
  {
    lat: 63.42931,
    lng: -18.90584,
    name: "Höfðabrekka",
    people: ["Höfðabrekka kemur oft við sögu eftir brennuna"],
    rank: 10,
  },
  {
    lat: 63.438124,
    lng: -18.902052,
    name: "Kerlingadalur",
    people: ["Þar vegur Kári nokkra brennumenn, með honum er Björn hvíti"],
    rank: 12,
  },
  {
    lat: 63.710005,
    lng: -18.637121,
    name: "Skaftártunga",
    people: [
      "Um Skaftártungu er reiðleið norður fyrir Mýrdalsjökul",
      "inn í Fljótshlíð og þaðan áfram um sveitir Suðurlands",
    ],
    rank: 12,
  },
  { lat: 63.802348, lng: -18.066393, name: "Sandar" },
  {
    lat: 63.59985,
    lng: -18.29258,
    name: "Meðalland",
    people: [
      "Brennumenn ríða erinda Flosa um Meðalland og lenda",
      "í bardaga við Kára og Björn hvíta við Kringlumýri",
    ],
    rank: 10,
  },
  {
    lat: 63.577143,
    lng: -18.377551,
    name: "Kringlumýri",
    people: [
      "Brennumenn ríða erinda Flosa um Meðalland og lenda",
      "í bardaga við Kára og Björn hvíta við Kringlumýri",
    ],
    rank: 12,
  },
  {
    lat: 63.989079,
    lng: -17.538502,
    name: "Lómagnúpur",
    people: ["Draumur Flosa um Járngrím og örlög brennumanna"],
    rank: 10,
  },
  {
    lat: 63.98507,
    lng: -16.891408,
    name: "Svínafell",
    people: ["Flosi Þórðarson"],
    rank: 1,
  },
  {
    lat: 64.12176,
    lng: -16.132359,
    name: "Breiðá",
    people: ["Þar eiga Kári og Hildigunnur bú undir lok sögunnar"],
    rank: 10,
  },
  { lat: 64.296972, lng: -15.166764, name: "Hornafjörður" },
  {
    lat: 64.225319,
    lng: -15.679666,
    name: "Borgarhöfn",
    people: ["Flosi gefur land þar í skiptum", "fyrir skip til að komast utan"],
    rank: 10,
  },
  {
    lat: 64.511244,
    lng: -14.496555,
    name: "Þvottá",
    people: ["Síðu-Hallur"],
    rank: 10,
  },
  {
    lat: 64.789675,
    lng: -14.138998,
    name: "Breiðdalur",
    people: ["Þangað sækir Flosi liðstyrk af Hallbirni sterka"],
    rank: 10,
  },
  {
    lat: 65.055198,
    lng: -14.875222,
    name: "Hrafnkelsstaðir",
    people: ["Hrafnkell Þórisson Hrafnkelssonar"],
    rank: 5,
  },
  {
    lat: 65.04564,
    lng: -14.93774,
    name: "Bersastaðir",
    people: ["Þangað sækir Flosi liðstyrk af Hólmsteini Spak-Bersasyni"],
    rank: 10,
  },
  {
    lat: 65.027031,
    lng: -14.949624,
    name: "Valþjófsstaðir",
    people: ["Sörli Brodd-Helgason, tengdasonur Guðmundar ríka"],
    rank: 10,
  },
  {
    lat: 65.540152,
    lng: -13.816754,
    name: "Njarðvík",
    people: [
      "Þar búa þeir bræður, Þorkell fullspakur",
      "og Þorvaldur. Flosi fær liðstyrk þeirra",
    ],
    rank: 10,
  },
  {
    lat: 65.733665,
    lng: -14.738198,
    name: "Krossavík",
    people: ["Þar sækir Flosi liðstyrk af Þorkeli Geitissyni"],
    rank: 10,
  },
  {
    lat: 65.784926,
    lng: -18.259361,
    name: "Möðruvellir",
    people: ["Guðmundur ríki"],
    rank: 1,
  },
  {
    lat: 59.924937,
    lng: 10.529759,
    name: "Víkin",
    people: ["Konungssetur, eitt af mörgum"],
    rank: 1,
  },
  {
    lat: 57.01491,
    lng: 14.662544,
    name: "Gautland",
    people: ["Syðsti hluti Svíþjóðar"],
    rank: 10,
  },
  {
    lat: 62.825397,
    lng: 13.73,
    name: "Jamtaland",
    people: ["Í mið-Svíþjóð, liggur að landamærum Noregs"],
    rank: 10,
  },
  {
    lat: 59.434151,
    lng: 17.389959,
    name: "Lögurinn",
    people: ["Umfangsmikið vatnasvæði vestur af Stokkhólmi"],
    rank: 10,
  },
  { lat: 55.803825, lng: 12.780324, name: "Eyrarsund" },
  {
    lat: 59.322505,
    lng: 18.082367,
    name: "Stokksund",
    people: ["Ós Lagarins við Stokkhólm"],
    rank: 9,
  },
  {
    lat: 58.601347,
    lng: 8.954446,
    name: "Limgarðssíða",
    people: ["Þar hitti Ögumundur Sóta"],
    rank: 10,
  },
  {
    lat: 65.189237,
    lng: 34.391004,
    name: "Garðaríki",
    people: ["Þar sem nú er Úkraína. Þar áttu norrænir menn ríki"],
    rank: 5,
  },
  {
    lat: 65.270824,
    lng: 34.027827,
    name: "Bjarmaland",
    people: [
      "Þar var hægt að fá verðmæta skinnavöru. Þetta var þó",
      "hættuför og ekki komust allir sem fóru lífs til baka",
    ],
    rank: 8,
  },
  {
    lat: 57.145291,
    lng: 24.457006,
    name: "Austurvegur",
    people: ["Landsvæðið við Eystrasalt og þar austur af"],
    rank: 1,
  },
  {
    lat: 59.268847,
    lng: 10.455609,
    name: "Túnsberg",
    people: ["Þar er konungssetur, eitt af mörgum"],
    rank: 1,
  },
  {
    lat: 57.76661,
    lng: 11.900973,
    name: "Hísing",
    people: ["Þar sem nú er Gautaborg"],
    rank: 10,
  },
  {
    lat: 56.131712,
    lng: 13.261561,
    name: "Smálönd",
    people: ["Þar herja Gunnar og Kolskeggur ásamt Hallvarði"],
    rank: 10,
  },
  {
    lat: 59.422258,
    lng: 24.958674,
    name: "Rafala",
    people: [
      "Í Austurvegi/Eistlandi.Þar herja Gunnar og Kolskeggur ásamt Hallvarði",
    ],
    rank: 8,
  },
  {
    lat: 58.512781,
    lng: 22.815852,
    name: "Eysýsla",
    people: [
      "Þangað fóru Gunnar og félagar eftir að hafa barist í Rafala.",
      "Hittu þar Tófa. Þar á Þorkell hákur einnig að hafa drepi flugdreka",
    ],
    rank: 8,
  },
  {
    lat: 54.628817,
    lng: 9.725515,
    name: "Heiðabær",
    people: [
      "Þangað koma Gunnar og félagar eftir hernaðinn í Svíþjóð og í Austurvegi.",
      "Gunnar hittir það Harald Gormsson Danakonung",
    ],
    rank: 10,
  },
  {
    lat: 41.014705,
    lng: 29.067529,
    name: "Mikligarður",
    people: ["Þar sem nú er Istanbúl, Tyrklandi"],
    rank: 1,
  },
  {
    lat: 68.428478,
    lng: 17.647079,
    name: "Hálogaland",
    people: ["Þar tekur Þráin land er hann siglir til Noregs"],
    rank: 10,
  },
  {
    lat: 63.423131,
    lng: 10.426445,
    name: "Þrándheimur",
    people: [
      "Að Hlöðum í Þrándheimi er konungsaðsetur.",
      "Þar hittir Þráinn Hákon jarl",
    ],
    rank: 5,
  },
  {
    lat: 59.103898,
    lng: -3.17346,
    name: "Orkneyjar",
    people: ["Þar ríkir Sigurður jarl Hlöðvisson"],
    rank: 1,
  },
  {
    lat: 58.638127,
    lng: -3.04707,
    name: "Dungalsbær",
    people: [
      "Þaðan eru Grjótgarður og Snækólfur,",
      "sem Kári og Njálssynir berjast við",
    ],
    rank: 10,
  },
  {
    lat: 59.043034,
    lng: -3.141934,
    name: "Hrossey",
    people: ["Aðsetur Sigurðar jarls í Orkneyjum"],
    rank: 10,
  },
  {
    lat: 58.680978,
    lng: -3.113327,
    name: "Straumey",
    people: ["Þar var Arnljótur, sýslumaður Sigurðar jarls"],
    rank: 10,
  },
  {
    lat: 58.590195,
    lng: -3.082294,
    name: "Þrasvík",
    people: [
      "Hundi jarl og Melsnati jarl tóku þar af",
      "lífi Hávarð, mág Sigurðar jarls í Orkneyjum",
    ],
    rank: 10,
  },
  {
    lat: 58.577871,
    lng: -3.355262,
    name: "Katanes",
    people: ["Nyrsti skagi Skotlands, á móti Orkneyjum"],
    rank: 6,
  },
  {
    lat: 58.724628,
    lng: -3.115878,
    name: "Pettlandsfjörður",
    people: ["Fjörðurinn milli Skotlands og Orkneyja"],
    rank: 10,
  },
  {
    lat: 57.752806,
    lng: -6.919298,
    name: "Suðureyjar",
    people: [
      "Kári Sölmundarson er frá Suðureyjum.",
      "Suðureyjarer reyndar allstór eyjaklasi, eyjarnar",
      "sunnan við þær tilheyra þessum eyjaklasa",
    ],
    rank: 1,
  },
  {
    lat: 57.592206,
    lng: -3.34911,
    name: "Mýræfi",
    people: ["Yfirráðasvæði Sigurðar jarls"],
    rank: 10,
  },
  {
    lat: 57.553788,
    lng: -3.954944,
    name: "Ross",
    people: ["Yfirráðasvæði Sigurðar jarls"],
    rank: 10,
  },
  {
    lat: 57.708897,
    lng: -4.316632,
    name: "Syðrilönd",
    people: ["Yfirráðasvæði Sigurðar jarls"],
    rank: 10,
  },
  {
    lat: 56.315549,
    lng: -5.33778,
    name: "Dalir",
    people: ["Yfirráðasvæði Sigurðar jarls"],
    rank: 10,
  },
  {
    lat: 51.910622,
    lng: -4.37864,
    name: "Bretland",
    people: ["Þar sem nú heitir Wales"],
    rank: 1,
  },
  {
    lat: 63.573414,
    lng: 9.69433,
    name: "Agðanes",
    people: [
      " Þar koma Hrappur og Kolbeinn að",
      "landi, skammt frá Þrándheimi",
    ],
    rank: 10,
  },
  {
    lat: 61.995759,
    lng: 9.250918,
    name: "Guðbrandsdali",
    people: ["Þar býr Guðbrandur í Dala"],
    rank: 8,
  },
  {
    lat: 55.471616,
    lng: -5.622226,
    name: "Saltíri",
    people: [
      "Þar herja Kári og Njálssynir, einnig um",
      "Suðureyjar, áður en þeir halda til Íslands",
    ],
    rank: 10,
  },
  {
    lat: 63.866226,
    lng: -21.162892,
    name: "Eyrar",
    people: [
      "Eyrarbakki. Þar koma Njálssynir og",
      "Kári að landi er þeir koma utan",
    ],
    rank: 5,
  },
  {
    lat: 63.404651,
    lng: -19.112564,
    name: "Dyrhólar",
    people: ["Kári og Helga Njálsdóttir eiga þar bú"],
    rank: 10,
  },
  {
    lat: 60.06095,
    lng: 23.036332,
    name: "Bálgarðssíða",
    people: ["Þar á Þorkell hákur að hafa drepið finngálkn"],
    rank: 10,
  },
  {
    lat: 63.79678,
    lng: -18.956646,
    name: "Sandur",
    people: ["Mælifellssandur (k. 131)"],
    rank: 5,
  },
  {
    lat: 59.529429,
    lng: -1.628697,
    name: "Friðarey",
    people: ["Þar taka Kári og Kolbeinn svarti land (k. 154)"],
    rank: 10,
  },
  {
    lat: 52.811155,
    lng: -8.450964,
    name: "Kunjáttuborg",
    people: ["Þar situr Brjánn, konunugur Írlands"],
    rank: 6,
  },
  {
    lat: 53.359443,
    lng: -6.235853,
    name: "Dyflinni",
    people: ["Skammt frá þar sem Dublin er", "núna átti Brjánsorusta sér stað"],
    rank: 5,
  },
  {
    lat: 57.876727,
    lng: 11.914001,
    name: "Konungahella",
    people: ["Eitt af aðsetrum Noregskonunga"],
    rank: 5,
  },
];
