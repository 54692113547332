import React, { useEffect, useState } from "react";
import { api } from "../model/api";
import ReactLoading from "react-loading";

export const PayButton = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    api.getPaymentData().then((data) => {
      setLoading(false);
      setData(data);
    });
  }, []);

  return (
    <div>
      {loading && (
        <div className="loading-spinner">
          <ReactLoading type="spin" color={"#777"} height={100} width={100} />
        </div>
      )}

      {!loading && (
        <form method="post" action={data.url}>
          {Object.keys(data.params || {}).map((key) => {
            const value = data.params[key];
            return <input type="hidden" name={key} key={key} value={value} />;
          })}
          <button className="intro-buy-button" disabled={loading}>
            Kaupa aðgang
          </button>
        </form>
      )}
    </div>
  );
};
