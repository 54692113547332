import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { Header } from "../../components/header";
import { MapSearch } from "./search";

import { places } from "./places";
import { Place } from "./place";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    const isVisible = (p) => {
      const rank = p.rank || 10;
      return props.bounds && props.bounds.contains(p) && rank < props.zoom;
    };
    return (
      <GoogleMap
        defaultMapTypeId="satellite"
        defaultZoom={7.1}
        defaultCenter={{ lat: 65.1085484, lng: -18.588049 }}
        zoom={props.zoom}
        center={props.center}
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
        onBoundsChanged={props.onBoundsChanged}
      >
        {props.showMarker &&
          places.map((p, index) => (
            <Place
              lat={p.lat}
              lng={p.lng}
              name={p.name}
              people={p.people}
              visible={isVisible(p)}
              key={index}
              selected={p.name === props.selectedPlaceName}
              onPlaceSelected={props.onPlaceSelected}
            />
          ))}
      </GoogleMap>
    );
  })
);

export class NjalaMap extends Component {
  state = {
    isMarkerShown: true,
    zoom: 7.3,
    center: { lat: 65.2085484, lng: -18.588049 },
    selectedPlaceName: "",
    searchOpen: false,
    searchResult: undefined,
  };
  searchInputRef = React.createRef();
  componentDidMount() {
    this.resetZoomAndCenter();
  }

  resetZoomAndCenter = () => {
    setTimeout(this.selectIsland, 1000);
  };

  selectIsland = () => {
    this.setState({
      zoom: 7.1,
      center: { lat: 65.1085484, lng: -18.588049 },
    });
  };

  selectNordurlond = () => {
    this.setState({
      zoom: 5.24,
      center: { lat: 61.8135806, lng: 21.0001719 },
    });
  };
  selectBretlandseyjar = () => {
    this.setState({
      zoom: 6.09,
      center: { lat: 55.622331, lng: -5.4562127 },
    });
  };
  selectPlace = (name) => {
    this.setState({
      selectedPlaceName: name,
    });
  };
  onMapMounted = (ref) => {
    console.log("onMapMounted");
    this.mapRef = ref;
  };
  onZoomChanged = () => {
    console.log("onZoomChanged", this.mapRef.getZoom());
    this.setState({
      zoom: this.mapRef.getZoom(),
    });
  };
  onBoundsChanged = () => {
    this.setState({
      bounds: this.mapRef.getBounds(),
    });
  };
  onSearchClick = () => {
    this.setState({
      searchOpen: true,
    });
  };
  onCloseSearch = () => {
    this.searchInputRef.current.value = "";
    this.setState({
      searchOpen: false,
      searchResult: undefined,
    });
  };
  onSearch = (searchValue) => {
    if (searchValue && searchValue.length > 1) {
      const value = searchValue.toLowerCase();
      this.setState({
        searchResult: places.filter(
          (place) =>
            place.name.toLowerCase().includes(value) ||
            place.people?.some((item) => item.toLowerCase().includes(value))
        ),
      });
    } else {
      this.setState({
        searchResult: undefined,
      });
    }
  };
  selectPlace = (place) => {
    this.setState({
      zoom: Math.max(11, place.rank + 0.1),
      center: { lat: place.lat, lng: place.lng },
      searchResult: undefined,
    });
  };
  render() {
    const b = this.state.bounds;
    if (b) {
      console.log("bounds", b.na, b.ga);
    }
    return (
      <div>
        <Header user={this.props.user} onSearchClick={this.onSearchClick} />
        <MapSearch
          open={this.state.searchOpen}
          onClose={this.onCloseSearch}
          inputRef={this.searchInputRef}
          selectPlace={this.selectPlace}
          onSearch={this.onSearch}
          result={this.state.searchResult}
        />
        <div className="body">
          <div className="sub-nav-container">
            <div className="sub-nav">
              <button className="sub-nav-link" onClick={this.selectIsland}>
                Ísland
              </button>
              <button className="sub-nav-link" onClick={this.selectNordurlond}>
                Norðurlönd
              </button>
              <button
                className="sub-nav-link"
                onClick={this.selectBretlandseyjar}
              >
                Bretlandseyjar
              </button>
            </div>
          </div>
        </div>
        <MyMapComponent
          zoom={this.state.zoom}
          center={this.state.center}
          showMarker={this.state.isMarkerShown}
          onMapMounted={this.onMapMounted}
          onZoomChanged={this.onZoomChanged}
          onBoundsChanged={this.onBoundsChanged}
          onPlaceSelected={this.selectPlace}
          selectedPlaceName={this.state.selectedPlaceName}
          bounds={this.state.bounds}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDrwOUuO5RlQ_eufExqu3C2iah3TnoZ5hE&v=3.exp"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="map" />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}
