import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

export const Aettareining = props => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="">
      <div className="about-section">
        <h2>Ættareiningin og feðraveldið</h2>
        <div className="about-section-text">
          <p>
            Það var ekki á hvers manns færi að nema hér land fyrir meira en
            þúsund árum síðan. Þeir sem fóru fyrir hópum sem sigldu yfir hafið
            til þess að koma sér fyrir í nýju landi hafa verið efnaðir menn.
            Bæði hafa þeir ráðið skipum sem voru nægilega stór til að sigla yfir
            úthafið sem og haft forystu fyrir hópi fólks sem sigldi með þeim,
            bæði skylduliði og öðrum. Landnemarnir hafa því verið komnir af
            efnuðu fólki, hvaðan svo sem þeir komu, frá Noregi, Írlandi eða frá
            byggðum norrænna manna af Bretlandseyjum. Einnig gætu þeir hafa
            efnast með víkingu eða af kaupmennsku. Forystumenn landnemanna námu
            stór landsvæði og gáfu fylgdarfólkinu af landnámi sínu sem var þá
            endurgoldið í hvers kyns stuðningi ef á þyrfti að halda, t.d. ef til
            deilna kæmi við aðra höfðingja (Vésteinn Ólason, 1998: 22).
          </p>
          <p>
            Það er einkenni þeirra sem nema ný lönd að varðveita uppruna sinn
            með því að segja sögur af forfeðrum sínum. Það er einnig einkenni
            sagnamennsku að sögur breytast og verða ýktari eftir því sem þær eru
            sagðar oftar. Afkomendur landnemanna eru stoltir af uppruna sínum og
            sögurnar bera þess merki. Þetta má meðal annars sjá af samtali
            Hallgerðar og Höskuldar, föður hennar, þegar hann tilkynnir henni að
            hún hafi verið gefin Þorvaldi Ósvífurssyni. Hallgerður er ósátt við
            að hafa verið gefin án þess að vera spurð álits á mannsefninu en
            Höskuldur ámælir henni þá fyrir ofmetnað. Hún svarar honum fullum
            hálsi, að hann sjálfur og frændalið hans hafi mikinn metnað „og er
            það eigi undarlegt að eg hafi nokkurn“ (10. kafli). Þar er
            Hallgerður að líkindum að vísa til þess að hafa frá því hún var ung
            stúlka hlustað á föður sinn og frændalið segja fræknar sögur af
            forfeðrum sínum, Katli flatnef sem var hersir í Noregi, landnemanum
            Unni djúpúðgu auk þess sem bæði maður hennar og sonur, Ólafur hvíti
            og Þorsteinn rauður, voru herkonungar. Þá eiga þau einnig ættir að
            rekja til hálfgoðsögulegu hetjunnar, Ragnars loðbrókar. Þessar sögur
            eiga sinn þátt í að skapa sjálfsmynd Hallgerðar eins og annarra
            ættmenna hennar og endurspeglast í hortugu svari hennar til föður
            síns.
          </p>
          <p>
            Höskuldur ámælir Hallgerði fyrir ofmetnað vegna þess að hann sem
            faðir hennar á að ráða högum hennar. Konur hafa sjaldnast nokkuð um
            það að segja hverjum þær eru giftar. Þannig virkar feðraveldið.
            Elsti karlmaðurinn í fjölskyldunni á að ráða öllum málum hennar. Í
            Njálu sjáum við að Njáll velur sonum sínum kvonfang. Synir hans
            hlíta því sem hann segir, samanber það sem Njáll segir við Gunnar
            eftir að hann hafði greitt Njáli vegna vígs Þórðar leysingjasonar:
            Gunnar spyr hvort hann vilji ekki hafa syni sína viðstadda en Njáll
            segir þess ekki þurfa „því eigi munu þeir rjúfa þá sátt er eg geri“
            (43. kafli). Þetta kemur einnig glögglega fram þegar Flosi kemur með
            lið sitt að Bergþórshvoli í þeim tilgangi að ganga milli bols og
            höfuðs á Njálssonum, Skarphéðinn vill verjast fyrir utan bæinn en
            Njáll vill að þeir verjist inn í bænum. Skarphéðinn virðir vilja
            föður síns en svarar að sínum kaldhæðna hætti: „En vel má eg gera
            það til skaps föður mínum að brenna inni með honum því að ek hræðist
            ekki dauða minn“ (128. kafli).
          </p>
          <p>
            Mörður gígja giftir Unni dóttur sína Hrúti án þess að hafa hana með
            í ráðum. Hallgerður er stórlát og átelur föður sinn fyrir að hafa
            sig ekki með í ráðum þegar hún er gefin Þorvaldi en fær þó að vera
            með í ráðum þegar hún er gefin Glúmi auk þess sem vilji hennar er
            augljós þegar hún er gefin Gunnari frá Hlíðarenda. Dóttir Hallgerðar
            og Glúms, Þorgerður, er gefin Þráni Sigfússyni og er ekki höfð með í
            ráðum. Hildigunnur Starkaðardóttir hefur hins vegar ekki aðeins val
            um hvort hún vilji giftast Höskuldi Þráinssyni heldur setur hún
            einnig það skilyrði að hún giftist eingöngu manni með goðorð.
          </p>
          <p>
            Feðrum er annt um að gifta dætur sínar inn í voldgugar og vel stæðar
            ættir. Mörður gígja gerir þeim bræðrum, Höskuldi og Hrúti, ljóst að
            sá maður sem vill giftast dóttur hennar þarf að leggja mikið í
            kaupmálann því hann ætlar sér ekki gifta hana neinum aukvisa. Mörður
            er virtur sem mikill lagamaður, hann er efnaður og af voldugum
            ættum. Unnur er eina barn hans og mum því ein erfa hann. Þegar
            bræðurnir fara á fund Marðar að biðja um hönd Unnar segir Mörður að
            hann þekki vel til Höskuldar og ættar hans en lítið til hálfbróður
            hans, Hrúts. Höskuldur kynnir bróður sinn með mjög jákvæðum hætti
            auk þess sem hann býðst til að leggja sitt til í kaupmálann svo
            kaupin megi ganga. Það dugir til þess að sannfæra Mörð um að hagur
            dóttur hans verði í samræmi við þá virðingu sem hann telur að hún
            eigi skilið. Konur litu þessa skipan mála yfirleitt jákvæðum augum
            því kona sem er vel gift „hefur fengið nýja og sterkari stöðu sem
            húsfreyja og nýtur valda og virðingar innan heimilisins“ (Vésteinn
            Ólason, 1993: 68).
          </p>
          <p>
            Konur gegna einnig mikilvægu hlutverki í að efla hagsmunatengsl í
            samfélaginu. Ekkert framvæmdavald var á þjóðveldistímanum og því
            varð sá sem vann mál á þingi sjálfur að sjá um að dómnum yrði
            framfylgt. Það var þó ekki á hvers manns færi að sækja mál á þingi.
            Lítils megandi maður með veikan eða engan bakhjarl gat stefnt
            sjálfum sér og sínum í hættu með því að sækja mál gegn öflugum
            höfðingjum. Í Hrafnkels sögu drepur höfðinginn Hrafnkell Freysgoði
            son fátæks bónda. Bóndinn, Þorbjörn að nafni, leggur mikið kapp í að
            fá Hrafnkel dæmdan en veit að hann hefur ekki afl í það einn síns
            liðs. Það hleypur hins vegar á snærið hjá Þorbirni er hann leitar
            eftir liðsinni á þingi því höfðingjar tveir af Vestfjörðum ganga í
            lið með honum. Það dugir til að fá Hrafnkel dæmdan sekan fyrir vígið
            á syni Þorbjörns. Einn síns liðs hefur Þorbjörn þann styrk sem til
            þarf til þess að fá Hrafnkel dæmdan. Í Laxdælu þrýstir Ósvífur mjög
            á dóttur sína Guðrúnu að ganga að eiga Bolla enda er hann af
            höfðingjaættum. Bræðrum Guðrúnar er einnig í mun um að hún giftist
            honum því þeim „þykir sér mikil slægja til mægða við Bolla“ (43.
            kafli). Það þýðir að staða þeirra í styrkist með því að tengjast
            Bolla og ætt hans. Í samfélagi án framkvæmdavalds gegna konur því
            mikilvægu hlutverki í að tengja saman öflugar ættir og efla þannig
            stöðu sína og virðingu.
          </p>
        </div>
      </div>
      <div className="about-story-parts">
        <div className="about-section">
          <h2>Heimildir</h2>
          <div className="about-section-text sources">
            <p>
              Vésteinn Ólason (1993). Mannlýsingar og hlutverkaskipti. Í
              Vésteinn Ólason (ritstjóri), Íslensk bókmenntasaga II (66–73).
              Reykjavík: Mál og menning.
            </p>
            <p>
              Vésteinn Ólason. (1998). Samræður við söguöld. Reykjavík: Mál og
              menning.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
