import React, { useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../model/api";
import { HamburgerButton } from "react-hamburger-button";

export const Search = (props) => {
  const [result, setResult] = useState();
  const search = (e) => {
    if (e.target.value && e.target.value.length > 3) {
      const newResult = api.search(e.target.value);
      setResult(newResult);
    } else {
      setResult(undefined);
    }
  };

  const selectChapter = (chapter) => {
    setResult(undefined);
    if (props.selectChapter) {
      props.selectChapter(chapter);
    }
  };
  return (
    <div className={props.open ? "search search-open" : "search search-closed"}>
      <div className="content-wrap">
        <div className="content relative">
          <div className="search-input">
            <input
              type="text"
              onKeyUp={search}
              ref={props.inputRef}
              placeholder="Leit"
              {...(props.open && { autoFocus: true })}
            />
            <button className="close-search">
              <HamburgerButton
                open={true}
                onClick={props.onClose}
                strokeWidth={4}
                height={24}
                color={"#999"}
              />
            </button>
          </div>
          {result && (
            <div className="search-result">
              {result.map((line, index) => (
                <div
                  className="search-line"
                  key={index}
                  onClick={() => selectChapter(line.chapter)}
                >
                  <Link to={`/${props.basePath}/${line.chapter}`}>
                    <div className="search-chapter">
                      {line.chapter}. {line.title}
                    </div>
                    <div
                      className="search-context"
                      dangerouslySetInnerHTML={{
                        __html: line.context,
                      }}
                    />
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
