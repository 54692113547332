import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

export const Sources = props => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="about-section">
      <h2>Heimildir</h2>
      <div className="about-section-text sources">
        <p>
          Einar Ól. Sveinsson (gaf út). (1971). Íslenzk fornrit XII. bindi:
          Brennu-Njáls saga. Reykjavík: Hið íslenzka fornritafélag.
        </p>
        <p />
        <p>
          Google Maps. (e.d.). Sótt af:{" "}
          <a href="https://www.google.com/maps/@64.9442736,19.3138631,384589m/data=!3m1!1e3">
            https://www.google.com/maps/@64.9442736,19.3138631,384589m/data=!3m1!1e3
          </a>
        </p>
        <p>
          Google Maps. (e.d.). Sótt af{" "}
          <a href="https://www.google.com/maps/@62.1031547,11.7610578,1542114m/data=!3m1!1e3">
            https://www.google.com/maps/@62.1031547,11.7610578,1542114m/data=!3m1!1e3
          </a>
        </p>
        <p>
          Google Maps. (e.d.). Sótt af{" "}
          <a href="https://www.google.com/maps/@55.1990985,5.6496899,1541012m/data=!3m1!1e3">
            https://www.google.com/maps/@55.1990985,5.6496899,1541012m/data=!3m1!1e3
          </a>
        </p>
        <p>
          Íslensk orðabók: A–L. (2003). Mörður Árnason (ritstjóri), (3. útgáfa,
          aukin og endurbætt). Reykjavík: Edda.
        </p>
        <p>
          Íslensk orðabók: M–Ö. (2003). Mörður Árnason (ritstjóri), (3. útgáfa,
          aukin og endurbætt). Reykjavík: Edda.
        </p>
        <p>
          Jón Böðvarsson (annaðist útgáfu). (2002). Brennu-Njáls saga.
          Reykjavík: Iðnú
        </p>
        <p>
          Steindór Steindórsson. (höf. frumtexta). (2008). Vegahandbókin.
          Örlygur Hálfdanarson og Eva Hálfdanardóttir (ritstjórn). Vegahandbókin
          ehf.
        </p>
        <p>
          Vésteinn Ólason. (1993). Mannlýsingar og hlutverkaskipti. Í Vésteinn
          Ólason (ritstjóri), Íslensk bókmenntasaga II (66–73). Reykjavík: Mál
          og menning.
        </p>
        <p>
          Vésteinn Ólason. (1998). Samræður við söguöld. Reykjavík: Mál og
          menning.
        </p>
        <p>
          Örnólfur Thorsson (umsjón með útgáfu). (2008). Brennu-Njáls saga.
          Reykjavík: Mál og menning
        </p>
      </div>
    </div>
    <Footer />
  </>
);
