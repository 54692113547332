import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { ArrowForward } from "../icons/arrow-forward";
import { PayButton } from "../components/payButton";

export const Index = (props) => (
  <>
    <Header user={props.user} />
    <div className="body">
      {(!props.user || !props.user.hasAccess) && (
        <div className="intro">
          <div className="intro-content">
            <div className="intro-image2" />
            <div className="intro-text">
              <h1>Stofna aðgang</h1>
              <div>
                <p>
                  Kennsluvefur í Brennu-Njáls sögu. Njála myndskreytt, annars
                  vegar með 150 myndum, teiknaðar af Þórhildi Jónsdóttur og hins
                  vegar 160 myndum sem unnar voru í teiknimyndaforriti af
                  ritstjóra vefsins. Aðgangur að vefnum kostar 2.350 kr. fyrir
                  önnina. Einnig er hægt að kaupa aðgang að hljóðskrá með
                  sögunni og kostar sá aðgangur 850 kr.
                </p>
              </div>
              <div>
                {/* <a href="/login">
                  <div className="intro-buy-button">Innskráning</div>
                </a> */}
                {!props.user && (
                  <a href="/login">
                    <div className="intro-buy-button">Innskráning</div>
                  </a>
                )}
                {props.user && !props.user.hasAccess && (
                  <div>
                    <PayButton />
                    <img src="/images/mc_vrt_pos.svg" height="80" width="80" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {props.user && (
        <div className="one-col">
          <div className="sub-page-intro">
            <h2 className="sub-page-title">Brennu-Njáls saga</h2>
            <p className="sub-page-description">
              Velkomin á kennsluvef í Brennu-Njáls sögu. Hér fyrir neðan er hægt
              að nálgast söguna með þrenns konar hætti. Prófaðu endilega alla
              möguleikana og veldu það sem hentar þér best eða nýttu þér að lesa
              söguna með þeim myndum sem þig langar hverju sinni.
            </p>
          </div>
        </div>
      )}
      <div className="one-col">
        <div className="story-links">
          <div className="story-link">
            <Link to="/njala1/1">
              <div className="story-link-image">
                <img src="/images/v1.png" alt="" />
              </div>
              <h3>Saga 1</h3>
              <div className="story-link-text">
                160 myndir unnar í teiknimyndaforriti. Nöfn helstu persóna með
                stærra letri og í litum. Feitletrað ýmislegt áhugavert í
                textanum, sumt einnig í litum.
              </div>
              <div className="story-link-button">
                Skoða dæmi <ArrowForward />
              </div>
            </Link>
          </div>
          <div className="story-link">
            <Link to="/njala2/1">
              <div className="story-link-image">
                <img src="/images/v2.jpg" alt="" />
              </div>
              <h3>Saga 2</h3>
              <div className="story-link-text">
                150 teiknaðar og litaðar myndir í anda þjóðveldisins. Nöfn
                helstu persóna með stærra letri og í litum. Feitletrað ýmislegt
                áhugavert í textanum, sumt einnig í litum.
              </div>
              <div className="story-link-button">
                Skoða dæmi <ArrowForward />
              </div>
            </Link>
          </div>
          <div className="story-link">
            <Link to="/njala3/1">
              <div className="story-link-image">
                <img src="/images/v3.png" alt="" />
              </div>
              <h3>Saga 3</h3>
              <div className="story-link-text">
                150 teiknaðar og litaðar myndir í anda þjóðveldisins. Þessi
                útgáfa af sögunni er með óbreyttu letri.
              </div>
              <div className="story-link-button">
                Skoða dæmi <ArrowForward />
              </div>
            </Link>
          </div>
        </div>
        <div className="sub-intro">
          <div className="sub-intro-image">
            <img src="/images/3b_atli_dr_kol.jpg" alt="" />
          </div>
          <div className="sub-intro-text">
            <h2>Einn helsti menningararfur Íslendinga í máli og myndum</h2>
            <p>
              Njáls saga (öll sagan) gerð aðgengileg með myndskreytingum,
              orðskýringum, leitarhnappi, staðakortum, ættartrjám, fjölbreyttum
              verkefnum og fræðilegu efni.
            </p>
            <p>
              Sögunni er skipt upp í átta frásagnarhluta og þeim fylgja 240
              krossaspurningar (í sex spurningapökkum) og fjöldi Quizlet
              verkefna. Boðið er upp á að kaupa aðgang að hljóðbók með sögunni.
              Hallmar Sigurðsson les.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
