import React, { useEffect, useState } from "react";
import { Menu } from "../components/menu";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Search } from "../components/search";
import { api } from "../model/api";
import ReactLoading from "react-loading";
import classnames from "classnames";

const isCorrect = (q) => q.options.some((opt) => opt.correct && opt.checked);

export const Quizzes = (props) => {
  const [loading, setLoading] = useState(false);
  const [quizzes, setQuizzes] = useState();
  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    setQuizzes(undefined);
    api
      .getQuizzesById(props.match.params.quizzesId)
      .then(setQuizzes)
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.quizzesId]);

  const confirm = () => {
    if (loading) {
      return;
    }

    if (quizzes.state === "Done") {
      const newQuizzes = {
        ...quizzes,
        state: "",
        questions: quizzes.questions.map((q) => ({
          ...q,
          options: q.options.map((opt) => ({
            ...opt,
            checked: false,
          })),
        })),
      };
      setQuizzes(newQuizzes);
      window.scrollTo(0, 0);
    } else {
      setQuizzes({
        ...quizzes,
        state: "Done",
      });
    }
  };

  const handleSelectOption = (event) => {
    const [questionId, valueId] = event.target.value.split("-");
    if (quizzes) {
      setQuizzes({
        ...quizzes,
        questions: quizzes.questions.map((q) => {
          if (q.id === questionId) {
            return {
              ...q,
              options: q.options.map((opt) => ({
                ...opt,
                checked: opt.id === valueId,
              })),
            };
          }
          return q;
        }),
      });
    }
  };
  const basePath = props.match.params.basepath;
  // const answered = quizzes
  //   ? quizzes.questions.filter(q => q.options.some(o => o.checked)).length
  //   : 0;
  return (
    <>
      <Header
        user={props.user}
        extraClass="border-header"
        onSearchClick={props.handleSearchClick}
      />
      <Search
        open={props.searchOpen}
        basePath={basePath}
        onClose={props.closeSearch}
        inputRef={props.searchInputRef}
      />
      <div className="body">
        <Menu
          user={props.user}
          basePath={basePath}
          chapter={0}
          selectedQuizzesPart={quizzes ? quizzes.id : ""}
          chapterTitles={props.chapterTitles}
          menuOpen={props.menuOpen}
          toggleMenuOpen={props.toggleMenuOpen}
          closeMenu={props.closeMenu}
        />
        <div className="content-wrap">
          <div className="content">
            {loading && (
              <div className="loading-spinner">
                <ReactLoading
                  type="spin"
                  color={"#777"}
                  height={100}
                  width={100}
                />
              </div>
            )}
            {!loading && quizzes && (
              <>
                <h2 className="page-title">{quizzes.title}</h2>
                {quizzes.questions.map((q, index) => (
                  <div
                    className={classnames("question", {
                      "question-wrong":
                        quizzes.state === "Done" && !isCorrect(q),
                    })}
                    key={q.id}
                  >
                    <div className="question-text">
                      {index + 1}. {q.text}
                    </div>
                    <ul className="question-options">
                      {q.options.map((opt) => (
                        <li key={opt.id}>
                          <input
                            type="radio"
                            id={q.id + "-" + opt.id}
                            name={q.id}
                            value={q.id + "-" + opt.id}
                            onChange={handleSelectOption}
                            checked={Boolean(opt.checked)}
                          />
                          <label htmlFor={q.id + "-" + opt.id}>
                            {opt.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                {quizzes.state === "Done" && (
                  <div className="quizzes-grade">
                    {quizzes.questions.filter(isCorrect).length} rétt svör af{" "}
                    {quizzes.questions.length}
                  </div>
                )}
                <div className="quizzes-buttons">
                  <button className="button" onClick={confirm}>
                    {quizzes.state === "Done"
                      ? "Reyna aftur"
                      : "Fá niðurstöður"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
