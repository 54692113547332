import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => (
  <div className="footer">
    <div className="footer-info">
      ©Sagnavefurinn ehf.
    </div>
    <div className="footer-info"></div>
    <div className="footer-info">sagnavefurinn@gmail.com</div>
    <div className="footer-info">Umsjón með útgáfunni: Sverrir Árnason</div>
    <div className="footer-info">
      <Link to="/skilmalar">Skilmálar</Link>
    </div>
  </div>
);
