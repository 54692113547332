import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Route } from "react-router-dom";
import { Index } from "./routes/index";
import { Njala, withClass } from "./routes/njala";
import { Quizzes } from "./routes/quizzes";
import { Skilmalar } from "./routes/skilmalar";
import { NjalaMap } from "./routes/map";
import { AboutTheStoryOverview } from "./routes/aboutTheStory";
import { AboutTheStory } from "./routes/aboutTheStory/about";
import { AboutTheScript } from "./routes/aboutTheStory/aboutTheScript";
import { Sources } from "./routes/aboutTheStory/sources";
import { Aettareining } from "./routes/aboutTheStory/aettareining";
import { VoldAettartengslVirding } from "./routes/aboutTheStory/vold";
import { Vigid } from "./routes/aboutTheStory/vigid";
import { PartIntro } from "./routes/partIntro";
import { Glaerur } from "./routes/glaerur";
import {
  HopVerkefni,
  SkapandiVerkefni,
  OnnurVerkefni,
} from "./routes/projects";
import { Gullkistan } from "./routes/gullkistan";
import { api } from "./model/api";
import * as ReactGA from "react-ga";

const history = createBrowserHistory();

if (window.location.host === "brennunjalssaga.is") {
  ReactGA.initialize("UA-133575067-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + window.location.search);
  });
}
let prevLocation = {};
history.listen((location) => {
  const pathChanged = prevLocation.pathname !== location.pathname;
  const hashChanged = prevLocation.hash !== location.hash;
  if (
    (pathChanged || hashChanged) &&
    location.pathname.indexOf("/verkefni/") === -1
  ) {
    window.scrollTo(0, 0);
  }
  prevLocation = location;
});

export const withProps = (Comp, baseProps) => (props) =>
  <Comp {...props} {...baseProps} />;

const Njala1 = withClass(Njala, "njala-1", "njala1");
const Njala2 = withClass(Njala, "njala-2", "njala2");
const Njala3 = withClass(Njala, "njala-3", "njala3");

class App extends Component {
  state = {
    menuOpen: false,
    searchOpen: false,
    chapterTitles: {},
  };
  searchInputRef = React.createRef();

  componentDidMount() {
    fetch("/notandi")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((user) => {
        this.setState({
          user,
        });
      });
    api.getChapterTitles().then((chapterTitles) => {
      this.setState({ chapterTitles });
    });
  }
  toggleMenuOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };
  closeMenu = () => {
    this.setState({ menuOpen: false });
  };
  handleSearchClick = () => {
    this.setState({ searchOpen: true, menuOpen: false });
    this.searchInputRef.current.focus();
  };

  closeSearch = () => {
    console.log("close search");
    this.searchInputRef.current.value = "";
    this.setState({ searchOpen: false });
  };

  render() {
    const { user, chapterTitles, menuOpen, searchOpen } = this.state;
    return (
      <Router history={history}>
        <React.Fragment>
          <div>
            <Route
              path="/"
              exact
              component={withProps(Index, {
                user,
              })}
            />
            <Route
              path="/umsoguna"
              exact
              component={withProps(AboutTheStoryOverview, {
                user,
              })}
            />
            <Route
              path="/umsoguna/kynning"
              exact
              component={withProps(AboutTheStory, {
                user,
              })}
            />
            <Route
              path="/umsoguna/handrit"
              exact
              component={withProps(AboutTheScript, {
                user,
              })}
            />
            <Route
              path="/umsoguna/heimildir"
              exact
              component={withProps(Sources, {
                user,
              })}
            />
            <Route
              path="/umsoguna/aettareining"
              exact
              component={withProps(Aettareining, {
                user,
              })}
            />
            <Route
              path="/umsoguna/vold-aettartengsl-virding"
              exact
              component={withProps(VoldAettartengslVirding, {
                user,
              })}
            />
            <Route
              path="/umsoguna/vigid"
              exact
              component={withProps(Vigid, {
                user,
              })}
            />
            <Route
              path="/verkefni/hop"
              exact
              component={withProps(HopVerkefni, {
                user,
              })}
            />
            <Route
              path="/verkefni/skapandi"
              exact
              component={withProps(SkapandiVerkefni, {
                user,
              })}
            />
            <Route
              path="/verkefni/glaerur"
              exact
              component={withProps(Glaerur, {
                user,
              })}
            />
            <Route
              path="/verkefni/onnur"
              exact
              component={withProps(OnnurVerkefni, {
                user,
              })}
            />

            <Route
              path="/gullkistan"
              exact
              component={withProps(Gullkistan, {
                user,
              })}
            />
            <Route
              path="/skilmalar"
              exact
              component={withProps(Skilmalar, {
                user,
              })}
            />
            <Route
              path="/njala1/:chapter"
              component={withProps(Njala1, {
                user,
                chapterTitles,
                menuOpen,
                searchOpen,
                searchInputRef: this.searchInputRef,
                toggleMenuOpen: this.toggleMenuOpen,
                closeMenu: this.closeMenu,
                handleSearchClick: this.handleSearchClick,
                closeSearch: this.closeSearch,
              })}
            />
            <Route
              path="/njala2/:chapter"
              component={withProps(Njala2, {
                user,
                chapterTitles,
                menuOpen,
                searchOpen,
                searchInputRef: this.searchInputRef,
                toggleMenuOpen: this.toggleMenuOpen,
                closeMenu: this.closeMenu,
                handleSearchClick: this.handleSearchClick,
                closeSearch: this.closeSearch,
              })}
            />
            <Route
              path="/njala3/:chapter"
              component={withProps(Njala3, {
                user,
                chapterTitles,
                menuOpen,
                searchOpen,
                searchInputRef: this.searchInputRef,
                toggleMenuOpen: this.toggleMenuOpen,
                closeMenu: this.closeMenu,
                handleSearchClick: this.handleSearchClick,
                closeSearch: this.closeSearch,
              })}
            />
            <Route
              path="/hluti/:basepath/:part"
              component={withProps(PartIntro, {
                user,
                chapterTitles,
                menuOpen,
                searchOpen,
                searchInputRef: this.searchInputRef,
                toggleMenuOpen: this.toggleMenuOpen,
                closeMenu: this.closeMenu,
                handleSearchClick: this.handleSearchClick,
                closeSearch: this.closeSearch,
              })}
            />
            <Route
              path="/krossaspurningar/:basepath/:quizzesId"
              component={withProps(Quizzes, {
                user,
                chapterTitles,
                menuOpen,
                searchOpen,
                searchInputRef: this.searchInputRef,
                toggleMenuOpen: this.toggleMenuOpen,
                closeMenu: this.closeMenu,
                handleSearchClick: this.handleSearchClick,
                closeSearch: this.closeSearch,
              })}
            />
            <Route path="/kort" component={withProps(NjalaMap, { user })} />
          </div>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
