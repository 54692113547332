import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

const items = [
  {
    title: "Brennu-Njáls saga í máli og myndum",
    text: "Inngangsorð að sögunni og skiptingu hennar í átta hluta þar sem hver hluti felur í sér ákveðna atburðarrás og/eða ákveðin persóna ber uppi hverja atburðarrás. Dregið er fram það helsta sem einkennir hvern og einn hluta sögunnar.",
    image: "/images/about/kynning.png",
    link: "/umsoguna/kynning",
  },
  {
    title: "Ættareiningin og feðraveldið",
    text: "Um landnemana og afkomendur þeirra og stolt þeirra af uppruna sínum. Einnig um hvernig feðraveldið virkaði á þjóðveldistímanum og hvernig konur gegndu mikilvægu hlutverki í að efla hagsmunatengsl milli höfðingja.",
    image: "/images/about/aettareininginogfedraveldir.png",
    link: "/umsoguna/aettareining",
  },
  {
    title: "Völd, ættartengsl og virðing",
    text: "Um það hvernig togstreita sem fyrir er í samfélaginu á Suðurlandi og barátta um völd eru hreyfiafl atburða í sögunni. Einnig um mikilvægi virðingar og hvernig það hugtak á sinn þátt í atburðarrásinni.",
    image: "/images/about/voldaettartengslvirding.png",
    link: "/umsoguna/vold-aettartengsl-virding",
  },
  {
    title:
      "Vígið við Sandhólaferju og ættartengsl Gunnars á Hlíðarenda og Marðar gígju",
    text: "Mörður Valgarðsson er einn höfuðóvina Gunnars á Hlíðarenda. Þeir eru þó skyldir en rætur tengsla þeirra á milli má má rekja til rifrildis og vígs við Sandhólaferju en ferjan var á sínum tíma rétt fyrir ofan ósa Þjórsár.",
    image: "/images/about/sandholaferja.png",
    link: "/umsoguna/vigid",
  },
  {
    title: "Um handrit Brennu-Njáls sögu",
    text: "Brennu-Njáls saga er eins og aðrar Íslendingasögur höfundarlaus og aðeins varðveitt í eftirritum. Nítján handrita sögunnar eru talin vera frá 1300–1550. Elstu handritin eru Gráskinna og Reykjabók.",
    image: "/images/about/umhandritbrennu-njalssogu.png",
    link: "/umsoguna/handrit",
  },
  {
    title: "Heimildir",
    text: "Bergsteinn Gizurarson. (2000, 7. okt.). Hringurinn frá Rangá II. mbl.is. Sótt af https://www.mbl.is/greinasafn/grein/563391/ Einar Ól. Sveinsson (gaf út). (1971). Íslenzk fornrit XII. bindi: Brennu-Njáls saga. Reykjavík",
    image: "/images/about/heimildir.png",
    link: "/umsoguna/heimildir",
  },
];

export const AboutTheStoryOverview = (props) => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="about-overview">
      {items.map((item) => (
        <Link to={item.link} key={item.title}>
          <div className="about-item">
            <div className="about-item-image">
              <img src={item.image} alt={item.title} />
            </div>
            <div className="about-item-text">
              <h2>{item.title}</h2>
              <div className="">
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
    <Footer />
  </>
);
