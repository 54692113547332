import React from "react";
import { NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";

const seq = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
};

const StoryPart = ({
  user,
  start,
  end,
  title,
  introPart,
  selectedIntroPart,
  quizzesPart,
  selectedQuizzesPart,
  basePath,
  chapter,
  onClick,
  chapterTitles,
}) => (
  <AccordionItem
    expanded={
      (start <= chapter && end >= chapter) ||
      (quizzesPart && selectedQuizzesPart === quizzesPart) ||
      (introPart && introPart === selectedIntroPart)
    }
  >
    <AccordionItemTitle>
      <h4 className="story-part-item">
        <div className="story-part-range">
          {start} - {end}
        </div>
        <div className="story-part-title">{title}</div>
      </h4>
    </AccordionItemTitle>
    <AccordionItemBody>
      <div>
        <NavLink to={`/hluti/${basePath}/${introPart}`}>
          <div className="menu-questions">Um {introPart}. hluta</div>
        </NavLink>
      </div>
      {seq(start, end)
        .filter((index) => chapterTitles[index])
        .map((index) => (
          <div key={index} onClick={onClick}>
            <NavLink to={`/${basePath}/${index}`} className="menu-chapter-item">
              <div className="menu-chapter-index">{index}.</div>
              <div className="menu-chapter-name">
                {chapterTitles[index] || "Kafli"}
              </div>
            </NavLink>
          </div>
        ))}
      {quizzesPart && (
        <div>
          <NavLink to={`/krossaspurningar/${basePath}/${quizzesPart}`}>
            <div className="menu-questions">40 krossaspurningar</div>
          </NavLink>
        </div>
      )}
    </AccordionItemBody>
  </AccordionItem>
);

const storyParts = [
  {
    title: "Hrúts þáttur",
    start: 1,
    end: 17,
    quizzesPart: "hluti1",
  },
  {
    title: "Gunnar og Njáll",
    start: 18,
    end: 31,
  },
  {
    title: "Húskarlavígin",
    start: 32,
    end: 45,
    quizzesPart: "hluti2",
  },
  {
    title: "Þjófnaðurinn að Kirkjubæ",
    start: 46,
    end: 56,
  },
  {
    title: "Hestaatsmálið og víg Gunnars",
    start: 57,
    end: 81,
    quizzesPart: "hluti3",
  },
  {
    title: "Hrapps þáttur Örgumleiðasonar",
    start: 82,
    end: 93,
    quizzesPart: "hluti4",
  },
  {
    title: "Brennu þáttur",
    start: 94,
    end: 132,
    quizzesPart: "hluti5",
  },
  {
    title: "Kára þáttur og sögulok",
    start: 133,
    end: 159,
    quizzesPart: "hluti6",
  },
];

export const Menu = ({
  user,
  basePath,
  chapter,
  selectedQuizzesPart,
  selectedIntroPart,
  menuOpen,
  closeMenu,
  toggleMenuOpen,
  chapterTitles = {},
}) => {
  const storyPart =
    storyParts.find((p) => p.start <= chapter && p.end >= chapter) ||
    storyParts[0];
  return (
    <>
      <button className="story-menu-dropdown" onClick={toggleMenuOpen}>
        <h4 className="story-part-item">
          <div className="story-part-range">
            {storyPart.start} - {storyPart.end}
          </div>
          <div className="story-part-title">{storyPart.title}</div>
        </h4>
      </button>
      <div className={menuOpen ? "menu menu-open" : "menu menu-closed"}>
        <div className="chapter-menu">
          <Accordion>
            {storyParts
              .filter((part) => chapterTitles[part.start])
              .map((part, index) => (
                <StoryPart
                  key={part.title}
                  title={part.title}
                  start={part.start}
                  end={part.end}
                  user={user}
                  quizzesPart={part.quizzesPart}
                  selectedQuizzesPart={selectedQuizzesPart}
                  introPart={Number(index + 1)}
                  selectedIntroPart={selectedIntroPart}
                  basePath={basePath}
                  chapter={chapter}
                  onClick={closeMenu}
                  chapterTitles={chapterTitles}
                />
              ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};
