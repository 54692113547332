import React, { Component } from "react";
import { Marker } from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import classnames from "classnames";

export class Place extends Component {
  state = { over: false };
  onMouseOver = () => {
    this.setState({ over: true });
  };
  onMouseOut = () => {
    this.setState({ over: false });
  };
  render() {
    const { lat, lng, name, people = [], visible, onPlaceSelected } = this.props;
    return (
      <Marker
        position={{ lat, lng }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={() => onPlaceSelected(name)}
        options={{
          visible
        }}
      >
        <InfoBox
          options={{ enableEventPropagation: true, closeBoxURL: ``, visible }}
          zIndex={this.state.over ? 999 : 0}
          onClick={() => onPlaceSelected(name)}
          >
          <div
            className={classnames("place-label-wrap", {
              "place-label-wrap-over": this.state.over
              })}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
          >
            <div className="place-label">
              <div className="place-name">{name}</div>
              <div className={classnames("place-people", {
                "place-people-over": this.state.over
                })}>
                {people.map(p => (
                  <div key={p}>{p}</div>
                ))}
              </div>
            </div>
          </div>
        </InfoBox>
      </Marker>
    );
  }
}
