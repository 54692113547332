import React from "react";
export const Download = ({ color }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 16.5L18 28.5L6 16.5"
      stroke="#510A32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 28.5V1.5"
      stroke="#510A32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 34.5H1.5"
      stroke="#510A32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
