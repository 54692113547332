import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

const items = [
  {
    title: "Reykjabók Njálu",
    text: "Svanhildur Óskarsdóttir kynnir í stuttu myndskeið (tæpar 3 mín.) eitt elsta handrit Njálu. Svanhildur er handritafræðingur á Stofnun Árna Magnússonar í íslenskum fræðum.",
    href: "https://www.arnastofnun.is/is/reykjabok-njalu-am-468-4to?fbclid=IwAR2pTgWGJ2O-o_CCpAQoQUtyTN5NgR01RKczF2mVvyHzzDYSHAyga0iXWjI",
    image: "/images/gullkistan/reykjabok.png",
  },
  {
    title: "Þingvellir og sagan",
    text: "Þessi síða fjallar um hlutverk Lögbergs og Lögréttu, um dóma, þinghelgina, kristnitökuna og margt fleira sem tengist þessum merka stað.",
    href: "https://www.thingvellir.is/fraedsla/saga/",
    image: "/images/gullkistan/thingvallasidan.png",
  },
  {
    title: "Sighvatur rauði og víg við Sandhólaferju",
    text: "Grein um það hvort Sighvatur rauði hafi verið veginn við Sandhólaferju eða Sigmundur. Landnámuheimildir herma að það hafi verið Sigmundur en Njála segir það hafa verið Sighvat.",
    href: "https://www.mbl.is/greinasafn/grein/796699/",
    image: "/images/gullkistan/vig-sandholaferja.png",
  },
  {
    title: "Hringurinn frá Rangá",
    text: "Grein eftir Bergstein Gizurarson sem skrifar um mögulegan uppruna boga Gunnars frá Hlíðarenda.",
    href: "https://www.mbl.is/greinasafn/grein/562079/",
    image: "/images/gullkistan/hringurinnfra-ranga.png",
  },
  {
    title: "Á slóð hornbogans",
    text: "Seinni grein Bergsteins Gizurarsonar um boga Gunnars frá Hlíðarenda og tengsl hans við atburðarrás sem fór af stað með víginu við Sandhólaferju.",
    href: "https://www.mbl.is/greinasafn/grein/563391/",
    image: "/images/gullkistan/hornbogagreinin.png",
  },
  {
    title: "Njáls saga og hefðin sem áheyrendur þekktu",
    text: "Grein Gísla Sigurðssonar um mögulegan munnlegan bakbrunn Njálu. Gísli er rannsóknarprófessur við Stofnun Árna Magnússonar í íslenskum fræðum.",
    href: "https://skemman.is/bitstream/1946/6776/3/59-66_GisliSigurdar_FELMANbok.pdf",
    image: "/images/gullkistan/greingislasigurdssonar.png",
  },
  {
    title: "Áhugaverð síða um víkinga (Hurstwic)",
    text: "Á síðunni kemur fjölmargt fram um hætti norrænna manna til forna. Til að mynda um híbýli þeirra, fatnað, vopn, um ferðir norrænna manna og margt fleira.",
    href: "http://www.hurstwic.com/history/text/history.htm",
    image: "/images/gullkistan/ahugaverdsidaumvikinga.png",
  },
  {
    title: "Hver skrifaði Njálu og hvenær var hún skrifuð?",
    text: "Stutt grein eftir Véstein Ólason um vangaveltur manna hver sé höfundur Brennu-Njáls sögu. Vésteinn er fyrrverandi forstöðumaður Stofnunar Árna Magnússonar í íslenskum fræðum.",
    href: "https://www.visindavefur.is/svar.php?id=731",
    image: "/images/gullkistan/hverskrifadinjalu.png",
  },
  {
    title: "Samkvæmisleikir og staðreyndir um Njálu",
    text: "Einar Kárason, rithöfundur, færir rök fyrir því að Sturla Þórðarson sé höfundur Njálu.",
    href: "https://eyjan.dv.is/eyjan/2017/11/24/samkvaemisleikir-og-stadreyndir-um-njalu/",
    image: "/images/gullkistan/einarkarason.png",
  },
  {
    title: "Hvers vegna eru Íslendingasögur höfundarlausar?",
    text: "Örstutt grein grein eftir Véstein Ólason þar sem hann færir rök fyrir því hvers vegna Íslendingasögurnar séu höfundalausar. Vésteinn er fyrrverandi forstöðumaður Stofnunar Árna Magnússonar í íslenskum fræðum.",
    href: "https://www.visindavefur.is/svar.php?id=732",
    image: "/images/gullkistan/hofundarlausar.png",
  },
  {
    title: "Af RUV – Kveikur",
    text: "Þáttur í umsjón Þóru Arnarsdóttur þar sem koma fram skoðanaskipti um það hver sé mögulegur höfundur Njálu.",
    href: "https://www.ruv.is/kveikur/ad-skrifa-eda-ekki-skrifa-njalu/",
    image: "/images/gullkistan/samkvaemisleikir.png",
  },
  {
    title: "Lykillinn að Njálu",
    text: "Grein af mbl.is um bók Kristjáns Jóh. Jónssonar, Lykillinn að Njálu, sem fjallar meðal annars um mögulega fyrirrennara Njálu.",
    href: "https://www.mbl.is/greinasafn/grein/422241/",
    image: "/images/gullkistan/lykillinnadnjalu.png",
  },
];
export const Gullkistan = (props) => (
  <>
    <Header user={props.user} />
    <div className="gullkista-image" />
    <div className="one-col sub-page">
      <div className="sub-page-intro">
        <h2 className="sub-page-title">Gullkistan</h2>
        <p className="sub-page-description">
          Í Gullkistunni eru hlekkir á alls kyns ítarefni sem tengist Njálu.
          Gramsaðu og sjáðu til hvort þú finnir ekki gullmola sem tengist
          sögunni og vekur áhuga þinn.
        </p>
      </div>
      <div className="gullkista-items">
        {items.map((item, index) => (
          <a
            href={item.href}
            target="_new"
            className="gullkista-item"
            key={index}
          >
            <div className="gullkista-item-image">
              <img src={item.image} alt={item.title} />
            </div>
            <div className="gullkista-item-text">
              <h2>{item.title}</h2>
              <div className="">{item.text}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
    <Footer />
  </>
);
