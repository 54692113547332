import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

export const Skilmalar = (props) => (
  <>
    <Header user={props.user} />
    <div className="body">
      <div className="one-col sub-page">
        <div className="">
          <h2>Endurgreiðslustefna</h2>
          <p>
            Falli virkni vefsins niður sem nemur einni viku, hvort sem það er
            samfellt í eina viku eða samanlagt 7 dagar yfir lengri tíma, fær
            kaupandi áskriftina endurgreidda að fullu.
          </p>
          <h2>Lög og varnarþing</h2>
          <p>
            Samningur þessi er í samræmi við íslensk lög. Rísi mál vegna hans
            skal það rekið fyrir Héraðsdómi Reykjaness.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
