import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

export const Part1 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">1. hluti</span>{" "}
      <span className="about-story-part-name">Hrúts þáttur</span>
    </h2>
    <div className="about-section-text">
      <p>
        Í þessum hluta segir frá kynnum þeirra hálfbræðra,{" "}
        <b>Höskuldar Dala-Kollssonar</b> og <b>Hrúts Herjólfssonar</b>, við{" "}
        <b>Mörð gígju</b> og dóttur hans, <b>Unni</b>. Einnig utanferð Hrúts þar
        sem Hrútur kemst meðal annars í kynni við <b>Gunnhildi</b>, móður{" "}
        <b>Haraldar gráfelds</b> Noregskonungs. Þau kynni hafa þær afleiðingar
        að Unnur losar sig við Hrút en heimanmundur hennar verður eftir hjá
        Hrúti. Í þessum hluta segir einnig af fyrstu tveimur hjónaböndum{" "}
        <b>Hallgerðar langbrókur</b> en þar kemur Hrútur einnig við sögu.
      </p>
    </div>
  </div>
);

export const Part2 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">2. hluti</span>{" "}
      <span className="about-story-part-name">Gunnar og Njáll</span>
    </h2>
    <div className="about-section-text">
      <p>
        Hér segir af samskiptum <b>Gunnars á Hlíðarenda</b> og{" "}
        <b>Njáls á Bergþórshvoli</b>, helstu persónum sögunnar. Gunnar sannar
        sig sem hetja, annars vegar í samskiptum sínum við Hrút þar sem hann fær
        það hlutverk að endurheimta heimanmund Unnar af Hrúti og hins vegar
        þegar hann fer utan og kemst vegna verðleika sinna í kynni við bæði{" "}
        <b>Hákon jarl</b> í Noregi og <b>Harald Gormsson</b>, Danakonung. Í
        þessum hluta sýnir Njáll einnig visku sína sem og að hann er forspár
        maður.
      </p>
    </div>
  </div>
);

export const Part3 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">3. hluti</span>{" "}
      <span className="about-story-part-name">Húskarlavígin</span>
    </h2>
    <div className="about-section-text">
      <p>
        Í húskarlavígunum taka konurnar völdin, <b>Hallgerður langbrók</b> og{" "}
        <b>Bergþóra</b>, kona Njáls. Þessi hluti hefst þó á viðkynningu Gunnars
        og Hallgerðar og síðan brúðkaupi þeirra sem og viðbrögðum Njáls við
        samdrætti þeirra. Hlutirnir fara svo að gerast í <b>matarboðinu</b> að
        Bergþórshvoli þar sem Bergþóru og Hallgerði lendir saman. Hallgerður
        hefnir fyrir þá óvirðingu sem hún telur Bergþóru hafa sýnt sér og
        Bergþóra svarar jafnharðan. Áður en yfir lýkur hafa sjö húskarlar hafa
        fallið í valinn fyrir tilstuðlan þeirra og þar hafa Gunnar og Njáll
        varla undan að greiða hvor öðrum fyrir víg húskarlanna.
      </p>
    </div>
  </div>
);

export const Part4 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">4. hluti</span>{" "}
      <span className="about-story-part-name">Þjófnaðurinn að Kirkjubæ</span>
    </h2>
    <div className="about-section-text">
      <p>
        Í þessum hluta rætist forspá Hrúts um <b>þjófsaugu Hallgerðar</b>. Hér
        byrja einnig <b>vígaferli Gunnars</b>. Nokkuð reynir á Njál, visku hans
        og lögspeki, í því að koma Gunnari til hjálpar, bæði vegna þjófnaðarins
        sem og vegna fyrsta bardagans í sögunni sem á sér stað við Rangá. Í
        þessum hluta sögunnar berst hann við bóndann að Kirkjubæ, Otkel
        Skarfason og hans menn, meðal annars hinn vafasama Skammkel. Hér kemur{" "}
        <b>Mörður Valgarðsson</b> einnig til sögunnar, hann öfundar Gunnar og
        þykir ekki verra að geta átt þátt í að koma höggi á hann. Mörður er þó
        skyldur Gunnari því móðurafar þeirra, Mörður gígja og Sigfús, eru
        bræður. Mörður á svo eftir að koma töluvert meira við sögu og yfirleitt
        með fremur vafasömum hætti.
      </p>
    </div>
  </div>
);

export const Part5 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">5. hluti</span>{" "}
      <span className="about-story-part-name">
        Hestaatsmálið og víg Gunnars
      </span>
    </h2>
    <div className="about-section-text">
      <p>
        Hér halda vígaferli Gunnars áfram þar sem hann á jafnan hendur sínar að
        verja fyrir ójafnaðarmönnum. Hamagangurinn byrjar í <b>hestaati</b> þar
        sem <b>synir Starkaðar og Egils</b> beita óvönduðum meðulum til þess að
        hafa betur gegn Gunnari. Þeim hefnist fyrir það en sonur Starkaðar,{" "}
        <b>Þorgeir</b>, heitir því að linna ekki látum fyrr en hann sjái Gunnar
        dauðan. Í þessum hluta eiga tveir seinni bardagar Gunnars við Rangá sér
        stað. Hér leikur Mörður Valgarðsson stórt hlutverk í atburðarrás sem á
        endanum leiðir til dauða Gunnars.
      </p>
    </div>
  </div>
);

export const Part6 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">6. hluti</span>{" "}
      <span className="about-story-part-name">
        Hrapps þáttur Örgumleiðasonar
      </span>
    </h2>
    <div className="about-section-text">
      <p>
        Þessi hluti er kenndur við <b>Hrapp</b> þar sem hann leikur
        meginhlutverkið í atburðarrás sem hefst í Noregi en endar með bardaga
        Njálssona og Kára við <b>Þráinn Sigfússon</b>, Hrapp og liðsmenn þeirra
        við Markarfljót. Frásögnin hefst með utanferð Þráins og manna hans þar
        sem þeir taka land í Noregi en þar verður Þráinn hirðmaður Hákonar
        jarls. <b>Helgi og Grímur Njálssynir</b> sigla einnig utan og lenda í
        hremmingum við Orkneyjar þar sem þeir komast í kynni við{" "}
        <b>Kára Sölmundarson</b>. Hrappur fer einnig utan en af illri nauðsyn.
        Hann gerir síðan mikinn usla í ríki Hákonar jarls og þarf að flýja land
        en í þeirri atburðarrás verður til mikil flækja sem Þráinn Sigfússon og
        þeir bræður Helgi og Grímur dragast inn í og endar með blóðugum bardaga
        við Markarfljót.
      </p>
    </div>
  </div>
);

export const Part7 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">7. hluti</span>{" "}
      <span className="about-story-part-name">Brennu þáttur</span>
    </h2>
    <div className="about-section-text">
      <p>
        Tenging er á milli þessa hluta og hlutans á undan þar sem Njáll tekur{" "}
        <b>Höskuld</b>, son Þráins Sigfússonar, í fóstur í þeirri veiku von að
        koma í veg fyrir það sem hann virðist samt sem áður vita að muni gerast.
        Faðir Marðar, <b>Valgarður grái</b>, á „heiðurinn“ að þeirri atburðarrás
        sem fer af stað þar sem hann leggur upp ráðagerð sem hann fær syni sínum
        að fylgja eftir. Sú ráðagerð endar með vígi Höskuldar, málaferlum vegna
        þess og uppnámi á þingi sem síðan leiðir til hápunktar sögunnar,
        brennunnar að Bergþórshvoli. Þar fer höfðinginn <b>Flosi Þórðarson</b>{" "}
        fyrir hundrað manna liði að bænum í þeim tilgangi að ganga að Njálssonum
        dauðum.
      </p>
    </div>
  </div>
);

export const Part8 = () => (
  <div className="about-section">
    <h2>
      <span className="about-story-part-index">8. hluti</span>{" "}
      <span className="about-story-part-name">Kára þáttur og sögulok</span>
    </h2>
    <div className="about-section-text">
      <p>
        <b>Kári</b> sleppur úr brennunni og heitir að hefna. Málið fer þó fyrir
        þing sem verður sögufrægasta þing sem haldið hefur verið á Þingvöllum og
        er þar grípið til vopna. Þingið endar þó með dómum sem falla á
        brennumenn en Kári neitar að gangast undir sættirnar sem og{" "}
        <b>Þorgeir skorargeir</b>, frændi Njáls. Í kjölfarið hefjast
        hefndaraðgerðir Kára sem nýtur fyrst liðsinnis Þorgeirs og síðar{" "}
        <b>Bjarnar úr Mörk</b>. Leifar brennumanna komast þó utan með Flosa en
        hremmingum þeirra er þó ekki lokið, meðal annar vegna þess að Kári fer
        einnig utan á eftir þeim. Hápunktur þessa hluta sögunnar er orusta mikil
        á Írlandi sem margir brennumanna taka þátt í. Í lok sögunnar liggja svo
        leiðir Flosa og Kára aftur saman.
      </p>
    </div>
  </div>
);

export const AboutTheStory = (props) => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="about-page">
      <div className="about-section">
        <h2>Brennu-Njáls saga í máli og myndum</h2>
        <div className="about-section-text">
          <p>
            Brennu-Njáls sögu er yfirleitt skipt upp í tvo megin hluta, Gunnars
            hluta og Njáls hluta. Gunnar Hámundarson að Hlíðarenda er þó ekki
            kynntur til sögu fyrr en í 19. kafla, Njáll og fjölskylda hans í
            kaflanum á eftir. Tengingin við Gunnar er í gegnum fyrstu persónuna
            sem kynnt er til sögunnar, Mörð gígju, þegar dóttir Marðar, Unnur,
            leitar til Gunnars um að ná aftur heimanmundi hennar af Hrúti
            Herjólfssyni. Fram að 18. kafla snýst sagan annars vegar um Hrút og
            hins vegar um Hallgerði langbrók og tvö fyrstu hjónabönd hennar. Frá
            og með kafla 18. verður Gunnar á Hlíðarenda burðarás sögunnar eða
            allt þar til hann er veginn í kafla 77. Þessum hluta sögunnar lýkur
            með eftirmálum vegna vígs Gunnars og hvernig Kolskeggur bróðir hans
            hverfur úr sögunni í kafla 81.
          </p>
          {/* <div className="about-image-left">
            <img src="/images/v2.jpg" />
          </div> */}
          <p>
            Njáls hluti hefst með utanför frænda Gunnars, Þráins Sigfússonar,
            sem siglir til Noregs og sona Njáls, Helga og Gríms, þar sem segir
            frá kynnum þeirra bræðra við Kára Sölmundarson og Sigurð jarl í
            Orkneyjum. Í þessari utanför segir einnig frá þeim hremmingum sem
            Njálssynir lenda í í Noregi og kenna þeir Þráni Sigfússyni um.
            Undirrót þeirra hremminga má finna í alls kostulegri persónu, Hrappi
            Örgumleiðasyni, sem gerir mikinn óskunda í ríki Hákonar jarls og
            leitar á náðir Þráins á flótta sínum undan jarli. Þessi viðskipti
            eiga svo eftir að draga dilk á eftir sér og má rekja þann þráð allt
            til brennunnar á Bergþórshvoli í kafla 129. Við tekur það sem Bjarni
            Harðarson nefnir „hala sögunnar“, sem eru eftirmál vegna brennunnar
            og fleiri frásagnarverðir atburðir.
          </p>
        </div>
      </div>
    </div>
    <div className="about-story-parts">
      <Part1 />
      <Part2 />
      <Part3 />
      <Part4 />
      <Part5 />
      <Part6 />
      <Part7 />
      <Part8 />
    </div>
    <Footer />
  </>
);
