import React from "react";
import { Menu } from "../components/menu";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Search } from "../components/search";
import {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8
} from "./aboutTheStory/about";
const Parts = [Part1, Part2, Part3, Part4, Part5, Part6, Part7, Part8];

export const PartIntro = props => {
  const basePath = props.match.params.basepath;
  const part = Number(props.match.params.part);
  const PartComponent = Parts[part - 1];
  return (
    <>
      <Header
        user={props.user}
        extraClass="border-header"
        onSearchClick={props.handleSearchClick}
      />
      <Search
        open={props.searchOpen}
        basePath={basePath}
        onClose={props.closeSearch}
        inputRef={props.searchInputRef}
      />
      <div className="body">
        <Menu
          user={props.user}
          basePath={basePath}
          chapter={0}
          chapterTitles={props.chapterTitles}
          selectedIntroPart={part}
          menuOpen={props.menuOpen}
          toggleMenuOpen={props.toggleMenuOpen}
          closeMenu={props.closeMenu}
        />
        <div className="content-wrap">
          <div className="content">{PartComponent && <PartComponent />}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};
