import React from "react";
import { HamburgerButton } from "react-hamburger-button";

export const MapSearch = (props) => {
  const search = (e) => props.onSearch(e.target.value);

  const selectPlace = (place) => {
    if (props.selectPlace) {
      props.selectPlace(place);
    }
  };
  return (
    <div className={props.open ? "search search-open" : "search search-closed"}>
      <div className="content-wrap">
        <div className="content relative">
          <div className="search-input">
            <input
              type="text"
              onKeyUp={search}
              onFocus={search}
              ref={props.inputRef}
              placeholder="Leit"
            />
            <button className="close-search">
              <HamburgerButton
                open={true}
                onClick={props.onClose}
                strokeWidth={4}
                height={24}
                color={"#999"}
              />
            </button>
          </div>
          {props.result && (
            <div className="search-result">
              {props.result.map((place, index) => (
                <div
                  className="search-line"
                  key={index}
                  onClick={() => selectPlace(place)}
                >
                  {place.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
