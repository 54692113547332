import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

export const Vigid = props => (
  <>
    <Header user={props.user} />
    <div className="about-the-story-image" />
    <div className="">
      <div className="about-section">
        <h2>
          Vígið við Sandhólaferju og ættartengsl Gunnars á Hlíðarenda og Marðar
          gígju
        </h2>
        <div className="about-section-text">
          <p>
            Í þremur útgáfum Landnámubókar, Sturlubók, Hauksbók og Melabók,
            segir að Sigmundur Sighvatsson hafi fallið við Sandhólaferju en í
            Njálu segir að Sighvatur rauði hafi fallið þar. Í Njálu segir einnig
            að Sighvatur sé faðir Marðar gígju. Í Hauksbók og Melabók segir að
            Sigmundur sé faðir Marðar gígju og sonur Sighvats rauða. Í Sturlubók
            er þess ekki getið að Sigmundur sé faðir Marðar gígju svo út frá
            Njálu og Sturlubók Landnámu virðast ættartengslin ekki liggja alveg
            á hreinu.
          </p>
          <p>
            Ef gengið er út frá Landnámuútgáfunum að Sigmundur sé faðir Marðar
            gígju sjáum við að Rannveig, móðir Gunnars á Hlíðarenda, hafi verið
            gefin föður Gunnars, Hámundi, og að líkindum sem sáttagjöf milli
            ættar Marðar gígju og ættar Gunnars. Þetta hafi byrjað á rifrildi
            mill Steins Baugssonar, afabróður Gunnars, og Sigmundar við
            Sandhólaferju. Þeir hafi rifist um það hvor ætti að fara fyrr yfir
            Þjórsá með ferjunni og Steinn hafi vegið Sigmund í því rifrildi.
            Mágur Sigmundar, Önundur Hróarsson, hafi síðan drepið Stein, hvattur
            af Þorgerði, systur Sigmundar - eða dóttir ... (Um ættarölur í
            Landnámu, 2004). Þá var komið að bróður Steins að hefna, Gunnari
            Baugssyni. Sá Gunnar er afi aðalpersónu Njálu, Gunnars á Hlíðarenda.
            Gunnar Baugsson fær njósnir af Önundi í gegnum mág sinn, Örn í
            Vælugerði, fer á eftir honum og drepur hann en deyr svo sjálfur á
            heimleið af sárum sínum.
          </p>
          <p>
            Þar með ætti málunum að vera lokið. Synir Önundar, Sigmundur kleykir
            og Eilífur auðgi, eru þó ekki sáttir og vilja losna við Örn í
            Vælugerði sem er nábúi þeirra. Þar kemur Mörður gígja til sögunnar.
            Þeir fara á fund Marðar sem ræður þeim að fá skóggangssök á hendur
            Erni til þess að losna við hann úr héraðinu. Þeir tóku beitingarmál
            á hendur Erni sem varð sekur svo hann falli óheilagur fyrir sonum
            Önundar - nema í Vælugerði og í „örskotshelgi“ við landareign sína.
            Örskotshelgi er sú vegalengd sem hægt að að skjóta af boga af
            landareign manns. Ekki kemur þó fram hvaðan er skotið. Sigmundur og
            Elífur sátu um Örn sem gaf ekki færi á sér um stund. Um síðir kom að
            því að Örn rak naut út úr landareign sinni og þá drápu þeir bræður
            hann og töldu að Örn hefðu fallið óheilagur.
          </p>
          <p>
            Bróður Arnar, Þorleifur gneisti, fékk þó mann að nafni Þormóð
            Þjóstarson að hann helgaði Örn með því að skjóta af boga af
            landareign Arnar og skaut Þormóður svo langt að vígið var metið svo
            að það hafi orðið í örskotshelgi hans. Þar með urðu Önundarsynir
            sekir. Faðir Gunnars, Hámundur, og Þorleifur bróðir Arnar, mæltu þá
            eftir Örn og urðu þeir Sigmundur og Elífur héraðssekir úr Flóanum.
            Mörður gígja fékk þeim bræðrum báðum kvonfang en sem sáttagjöf milli
            ættar Marðar og ættar Gunnars var Rannveig gefin Hámundi, föður
            Gunnars á Hlíðarenda. Rannveig er ýmist sögð systir Marðar eða
            bróðurdóttir. Má geta sér til um hversu vel henni hefur líkað það að
            hafa verið nýtt þannig, að vera gift Hámundi en föðurbróðir hans
            drap föðurbróðir hennar, eða afa – allt eftir því hvaða heimildir er
            stuðst við.
          </p>
          <p>
            Getum hefur verið leitt að því að Rannveig hafi fengið bogann í
            heimanmund og þannig hafi hann síðar meir komist í hendur Gunnars á
            Hlíðarenda. Þó mætti telja líklegra, ef um sama boga er að ræða, að
            boginn hafi komst í hendur Hámundar í gegnum Þorleif gneista sem
            fékk Þormóð til að skjóta af boganum af landareign Arnar, því Örn
            var mágur föður Hámundar, Gunnars Baugssonar.
          </p>
        </div>
      </div>
      <div className="about-story-parts">
        <div className="about-section">
          <h2>Heimildir</h2>
          <div className="about-section-text sources">
            <p>
              Bergsteinn Gizurarson. (2000, 7. okt.). Hringurinn frá Rangá II.
              mbl.is. Sótt af https://www.mbl.is/greinasafn/grein/563391/
            </p>
            <p>
              Um ættartölur í Landnámu – Sighvatur rauði og víg við
              Sandhólaferju. (2004, 8. maí). mbl.is. Sótt af
              https://www.mbl.is/greinasafn/grein/796699/
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
