import React, { useEffect, useState } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Search } from "../components/search";
import { PayAudiobookButton } from "../components/payAudiobookButton";
import { Link } from "react-router-dom";
import { Menu } from "../components/menu";
import { api } from "../model/api";
import ReactLoading from "react-loading";
import escapeStringRegexp from "escape-string-regexp";
import { ArrowForward } from "../icons/arrow-forward";

function walkTheDOM(node, func) {
  func(node);
  node = node.firstChild;
  while (node) {
    walkTheDOM(node, func);
    node = node.nextSibling;
  }
}

const markMatch = (html, searchValue) => {
  const escapedSearchValue = escapeStringRegexp(searchValue);
  const tempEl = document.createElement("div");
  tempEl.innerHTML = html;

  walkTheDOM(tempEl, (node) => {
    if (
      node.nodeName === "#text" &&
      node.textContent.toUpperCase().indexOf(searchValue.toUpperCase()) >= 0
    ) {
      const wrap = document.createElement("span");
      wrap.innerHTML = node.textContent.replace(
        new RegExp("(" + escapedSearchValue + ")", "ig"),
        '<span class="search-match">$1</span>'
      );
      node.parentNode.replaceChild(wrap, node);
    }
  });

  return tempEl.innerHTML;
};
export const Njala = (props) => {
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState();
  useEffect(() => {
    setHtml(undefined);
    setLoading(true);
    api.getChapter(props.match.params.chapter).then((res) => {
      let newHtml = res;
      if (props.searchOpen && props.searchInputRef.current.value) {
        newHtml = markMatch(newHtml, props.searchInputRef.current.value);
      }
      setHtml(newHtml);
      setLoading(false);
    });
  }, [props.match.params.chapter, props.searchOpen, props.searchInputRef]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [html]);

  const handleHtmlClick = () => {};

  const chapter = Number(props.match.params.chapter);
  const basePath = props.basePath;
  return (
    <>
      <Header
        user={props.user}
        extraClass="border-header"
        onSearchClick={props.handleSearchClick}
      />
      <Search
        open={props.searchOpen}
        basePath={basePath}
        onClose={props.closeSearch}
        inputRef={props.searchInputRef}
      />
      <div className="body">
        {!loading && (
          <Menu
            user={props.user}
            basePath={basePath}
            chapter={chapter}
            chapterTitles={props.chapterTitles}
            menuOpen={props.menuOpen}
            toggleMenuOpen={props.toggleMenuOpen}
            closeMenu={props.closeMenu}
          />
        )}
        <div className="content-wrap">
          <div className="content">
            {loading && (
              <div className="loading-spinner">
                <ReactLoading
                  type="spin"
                  color={"#777"}
                  height={100}
                  width={100}
                />
              </div>
            )}
            {!loading && (
              <>
                {props.user &&
                  !props.user.hasAudiobookAccess &&
                  props.user.hasAccess && <PayAudiobookButton />}
                {props.user && props.user.hasAudiobookAccess && (
                  <audio
                    controls
                    controlsList="nodownload"
                    class="audio-player"
                  >
                    <source src={`/audiobook/${chapter}`} type="audio/mpeg" />
                  </audio>
                )}
                <div
                  onClick={handleHtmlClick}
                  dangerouslySetInnerHTML={{
                    __html: html,
                  }}
                />
                <div className="story-nav clear">
                  {chapter < 159 && (
                    <div className="story-nav-next">
                      <Link to={`/${basePath}/${chapter + 1}`}>
                        {chapter + 1}. kafli <ArrowForward color="#fff" />
                      </Link>
                    </div>
                  )}
                  {chapter > 1 && (
                    <div className="story-nav-prev">
                      <Link to={`/${basePath}/${chapter - 1}`}>
                        <ArrowForward color="#fff" /> {chapter - 1}. kafli
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export const withClass = (Comp, className, basePath) => (props) =>
  (
    <div className={className}>
      <Comp {...props} basePath={basePath} />
    </div>
  );
