import escapeStringRegexp from "escape-string-regexp";
function removeElements(html, selector) {
  var div = document.createElement("div"); // the container element
  div.innerHTML = html; // set it's html to 'html'
  var elements = div.querySelectorAll(selector); // select the elements that match the CSS selector 'selector'
  for (var i = 0; i < elements.length; i++) {
    elements[i].parentNode.removeChild(elements[i]);
  }
  return div.innerHTML;
}

const titleReqEx = /<span class="chapter-name">\n?(.*)\n?<\/span/;

export const createChapterModel = chapters => {
  const chapterTitles = Object.keys(chapters).reduce((obj, key) => {
    const titleRes = titleReqEx.exec(chapters[key]);
    obj[key] = titleRes ? titleRes[1] : key + ". kafli";
    return obj;
  }, {});

  let searchIndex;
  const createSearchIndex = () => {
    searchIndex = Object.keys(chapters).reduce((arr, key) => {
      const noHtml = removeElements(
        removeElements(chapters[key], ".skyring-frasa"),
        "h2"
      ).replace(/<(?:.|\n)*?>/gm, "");
      arr.push({
        index: key,
        text: noHtml.toLowerCase(),
        originalText: noHtml
      });
      return arr;
    }, []);
  };
  return {
    chapterTitles,
    searchChapters: searchString => {
      if (!searchIndex) {
        createSearchIndex();
      }
      const res = [];
      for (let i = 0; i < searchIndex.length; i++) {
        const obj = searchIndex[i];
        const index = obj.text.indexOf(searchString.toLowerCase());
        if (index >= 0) {
          const dotBeforeIndex = obj.text
            .substring(0, Math.max(0, index - 20))
            .lastIndexOf(".");
          const start = Math.max(
            0,
            dotBeforeIndex === -1 ? -1 : dotBeforeIndex + 2
          );
          const dotAfterIndex = obj.text.indexOf(".", index);
          const end =
            dotAfterIndex === -1 ? obj.text.length : dotAfterIndex + 1;
          const context = obj.originalText
            .substring(start, end)
            .replace(
              new RegExp("(" + escapeStringRegexp(searchString) + ")", "i"),
              "<b>$1</b>"
            );
          res.push({
            chapter: obj.index,
            title: chapterTitles[obj.index],
            context
          });
        }
      }
      return res;
    }
  };
};
